import * as React from 'react';
import { useEffect, useState } from "react";
import { RiskDetail, RiskDetailFinding } from "../../models/RiskDetail";
import risk_records from '../../data/19_3_1_grc_dashboard_risk_view.json';
import Legend from "../../components/table/Legend";
import NoteBoxSmall from "../../components/table/NoteBoxSmall";
import useModal from "../../components/modal/useModal";
import export_time from '../../data/creation-dates.json';
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

export type MyParams = {
    id: string;
};

function DetailsRisk() {
    const records = risk_records as RiskDetailFinding[];

    const { id } = useParams<keyof MyParams>() as MyParams;
    let [searchParams, setSearchParams] = useSearchParams();

    const { toggle } = useModal();
    const [data, setData] = useState("");
    const [riskDetails, setRiskDetails] = useState<RiskDetail[]>([]);
    const [filteredFindings, setFilteredFindings] = useState<RiskDetailFinding[]>([]);
    const [allRiskFindings, setAllRiskFindings] = useState<RiskDetailFinding[]>([]);
    const [selectedRule, setSelectedRule] = useState("")

    const [categoriesindicator, setcategoriesindicator] = useState<string[]>([])
    const [selectedIndicator, setSelectedIndicator] = useState("")
    const [risklevel, setrisklevel] = useState<string[]>([])
    const [selectedrisklevel, setSelectedRisklevel] = useState("")

    const UPDATETIME: string = export_time['19_3_1_grc_dashboard_risk_view_json'];

    const headers: string[] = [
        "Risk Indicator",
        "Production total"
    ]

    async function loadData(searchText: string, risklevel: string, riskindicator: string) {
        if (records !== undefined) {
            setAllRiskFindings(sortRiskDetails(records));
            setcategoriesindicator(records.filter((x, i, arr) => arr.findIndex(t => t.risklevel && (t.risklevel === x.risklevel)) === i).map(x => x.risklevel));
            setrisklevel(records.filter((x, i, arr) => arr.findIndex(t => t.riskindicator && (t.riskindicator === x.riskindicator)) === i).map(x => x.riskindicator));

            let filteredAndAggregated = aggregateFindings(records.filter(
                x => (searchText === '' || (x.applicationname.toLowerCase().indexOf(searchText.toLowerCase()) > -1)) &&
                    (risklevel === '' || x.risklevel === risklevel) &&
                    (riskindicator === '' || x.riskindicator === riskindicator)));
            setRiskDetails(filteredAndAggregated);
        }
    }

    useEffect(() => { // Initial load
        const id = searchParams.get("appname")
        if (id) {
            loadData(id, '', '');
        } else {
            loadData('', '', '');
        }

    }, []) // [] required so that useEffect is called once

    function aggregateFindings(findings: RiskDetailFinding[]): RiskDetail[] {
        let result: RiskDetail[] = []

        findings.forEach(finding => {
            let aggregatedFinding = result.filter(x => x.riskindicator === finding.riskindicator);
            if (aggregatedFinding.length === 0) {
                if (finding.risklevel === "High") {
                    result.push({
                        riskindicator: finding.riskindicator,
                        prodAccHigh: 1,
                        prodAccMedium: 0,
                        prodAccLow: 0,
                    })
                } else if (finding.risklevel === "Medium") {
                    result.push({
                        riskindicator: finding.riskindicator,
                        prodAccHigh: 0,
                        prodAccMedium: 1,
                        prodAccLow: 0,
                    })
                } else if (finding.risklevel === "Low") {
                    result.push({
                        riskindicator: finding.riskindicator,
                        prodAccHigh: 0,
                        prodAccMedium: 0,
                        prodAccLow: 1,
                    })
                }
            } else {
                if (finding.risklevel === "High") {
                    aggregatedFinding[0].prodAccHigh++;
                } else if (finding.risklevel === "Medium") {
                    aggregatedFinding[0].prodAccMedium++;
                } else if (finding.risklevel === "Low") {
                    aggregatedFinding[0].prodAccLow++;
                }
            }
        })
        setFilteredFindings(findings)
        return result;
    }

    function showFindings(riskindicatorName: string, riskLevelName: string) {
        setFilteredFindings(allRiskFindings.filter(x => (!selectedIndicator || x.status === selectedIndicator)
            && (!selectedrisklevel || x.risklevel === selectedrisklevel)
            && x.riskindicator === riskindicatorName
            && x.risklevel === riskLevelName));
        if (selectedIndicator === riskindicatorName && selectedrisklevel === riskLevelName) {
            setFilteredFindings(sortRiskDetails(allRiskFindings))
            riskindicatorName = "";
            riskLevelName = "";
        }
        setSelectedIndicator(riskindicatorName);
        setSelectedRisklevel(riskLevelName);
        toggle();
    }

    function sortRiskDetails(arr: RiskDetailFinding[]): RiskDetailFinding[] {
        const priorityOrder = ["High", "Medium", "Low"];
        arr.sort((a, b) => {
            const priorityA = priorityOrder.indexOf(a.risklevel);
            const priorityB = priorityOrder.indexOf(b.risklevel);

            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            } else {
                return b.riskkpi - a.riskkpi; // DESC, reverse if want ASC
            }
        });
        return arr;
    }

    function onSelectIndicator(indicatorName: string) {
        if (selectedIndicator === indicatorName) {
            indicatorName = "";
        }
        setSelectedIndicator(indicatorName);
        setSelectedRisklevel("");
        loadData('', selectedrisklevel, indicatorName);
    }

    function updateData(e: React.ChangeEvent<HTMLInputElement>) {
        setData(
            e.target.value
            // TODO how to put here object using Enum as properties? -> simple, useState<ObjectType>()
            //     {
            //     ...data,
            //     [e.target.name]: e.target.value
            // }
        )
        loadData(data, selectedrisklevel, selectedIndicator);
        if (e.target.value === "") {
            setData('');
            setSelectedIndicator("");
            setSelectedRisklevel("");
            setSelectedRule("");
            loadData('', '', '');
        }
    }

    return (
        <>
            {
                searchParams.get("appname") &&
                <div><h2>Application Details - {searchParams.get("appname")}</h2></div>
            }
            <div className="filter-list">
                {
                    risklevel.map((x, index) => (
                        <div key={index}
                            onClick={() => onSelectIndicator(x)}
                            className={x === selectedIndicator ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                            {x}
                        </div>
                    ))
                }
            </div>
            <div className="btn-group">
                <input className='label' placeholder="Application Name" type="text" onChange={updateData} value={data}></input>
            </div>
            <Legend updatetime={UPDATETIME}></Legend>
            {
                selectedIndicator && selectedrisklevel &&
                <div><h3><b>{selectedIndicator} - Risk Level: {selectedrisklevel}</b></h3></div>
            }
            {
                !selectedIndicator && selectedrisklevel &&
                <div><h3><b>Risk Level: {selectedrisklevel}</b></h3></div>
            }
            {
                selectedIndicator && !selectedrisklevel &&
                <div><h3><b>{selectedIndicator}</b></h3></div>
            }
            {
                !selectedIndicator && !selectedrisklevel &&
                <div><h3><b>All Risks</b></h3></div>
            }
            <table className="table-finding">
                <thead>
                    <tr>
                        {
                            headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        riskDetails.map((cg, index) => (
                            <tr key={index}>
                                <td style={{ width: "40em" }}>{cg.riskindicator}</td>
                                <td style={{ width: "20em" }}>
                                    <div className='countCell item-select' >
                                        {
                                            cg.prodAccHigh !== 0 &&
                                            <span
                                                onClick={() => showFindings(cg.riskindicator, "High")}
                                                className="box-list-item danger">{cg.prodAccHigh} High</span>
                                        }
                                        {
                                            cg.prodAccMedium !== 0 &&
                                            <span
                                                onClick={() => showFindings(cg.riskindicator, "Medium")}
                                                className="box-list-item info">{cg.prodAccMedium} Medium</span>
                                        }
                                        {
                                            cg.prodAccLow !== 0 &&
                                            <span
                                                onClick={() => showFindings(cg.riskindicator, "Low")}
                                                className="box-list-item general">{cg.prodAccLow} Low</span>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <table className="table-finding">
                <thead>
                    <tr>
                        <th>Application Name</th>
                        <th>Risk Level</th>
                        <th>Status</th>
                        <th>GREL Ticket</th>
                        <th>Finding Ticket</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredFindings.map((finding, index) => (
                            <tr key={index}>
                                <td style={{ width: "30em" }}>{finding.applicationname}</td>
                                <td style={{ width: "10em" }}><div><span className={finding.risklevel === "High" ? "box-list-item danger" : (finding.risklevel === "Medium" ? "box-list-item info" : "box-list-item general")}>{finding.risklevel}</span></div></td>
                                <td style={{ width: "10em" }}><div>{finding.status}</div></td>
                                <td style={{ width: "10em" }}><div><a href={`https://jira.platform.vwfs.io/browse/${finding.riskdescription}`}> {finding.riskdescription}</a></div></td>
                                <td style={{ width: "10em" }}><div><a href={`https://jira.platform.vwfs.io/browse/${finding.name}`}> {finding.name}</a></div></td>
                                <td style={{ width: "50em" }}>
                                    {
                                        finding.riskindicator === "Pentest" &&
                                        finding.status === "Open (non Prod)" &&
                                        <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC' text='Identified before go-live. Finding must be closed before go-live date.'>Documentation: <a href="https://csg.vwfs.io/grel/pentest/#solve-pentest-finding">Solve Pentest Finding</a></NoteBoxSmall></div>
                                    }
                                    {
                                        finding.riskindicator === "Pentest" &&
                                        finding.status === "Open" &&
                                        <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC' text='Finding must be closed before due date.'>Documentation: <a href="https://csg.vwfs.io/grel/pentest/#solve-pentest-finding">Solve Pentest Finding</a></NoteBoxSmall></div>
                                    }
                                    {
                                        finding.riskindicator === "Tech Review" &&
                                        finding.status === "Open (non Prod)" &&
                                        <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC' text='Identified before go-live. Finding must be closed before go-live date.'>Documentation: <a href="https://csg.vwfs.io/grel/techreview/#solve-finding">Solve Tech Review</a></NoteBoxSmall></div>
                                    }
                                    {
                                        finding.riskindicator === "Tech Review" &&
                                        finding.status === "Open" &&
                                        <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC' text='Finding must be closed before due date.'>Documentation: <a href="https://csg.vwfs.io/grel/techreview/#solve-finding">Solve Tech Review</a></NoteBoxSmall></div>
                                    }

                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default DetailsRisk;