import React from 'react';
import { NavLink, Outlet } from "react-router-dom";

function FindingsDetails() {


    return (
        <>
            <div className="tabs-horizontal">
                <ul id="demoTabs" className="nav" role="tablist">
                    <li role="presentation" className="nav-item">
                        <NavLink end to="" id="tab1" className="btn btn-link nav-link" aria-controls="panel1"
                            aria-selected="true" type="button">Process View</NavLink>
                    </li>
                    <li role="presentation" className="nav-item">
                        <NavLink to="/compliance-insight/control-appview" id="tab2" className="btn btn-link nav-link" aria-controls="panel2"
                            aria-selected="false" type="button">Application View</NavLink>
                    </li>
                </ul>
                {/* <section id="panel1" role="tabpanel" aria-labelledby="tab1">
                    ...
                </section> */}
            </div>
            <Outlet></Outlet>
        </>
    )
}

export default FindingsDetails;