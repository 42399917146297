import React from 'react';

interface Props {
    updatetime: string;
    isHidden?: boolean;
}

function Legend({ updatetime, isHidden = false}: Props) {
    return (
        <div className={ isHidden ? "hidden" : "divGeneral"}>
            Data aggregated as of <b>{updatetime}</b>, source data might be older.
            <div>Legend: <span className='box-list-item note'>R</span> Responsible (executing) <span className='box-list-item general'>A</span> Accountable <span className='box-list-item general'>C</span> Consulted </div>
        </div>
    )
}

export default Legend;