import React, { Component } from 'react';
import NoteBox from "./NoteBox"
import badge_release from "../../icons/badge_release.png"

class TableCell extends Component<any, any> {

    render() {
        const cg_text = "Compliance status must be above 95% and there must be no open issues with a severity level of High or Critical.";
        const cg_preview_text = "Frozen stable preview of the next current version.";
        
        return <>
            {
                this.props.category === "Security Not Compliant" &&
                <div className='tableCell divNotCompliant'>
                    {
                        this.props.current_cloudguardscore_workload >= 95 &&
                        <div>
                            <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                                <span className='box-list-item danger'>Current</span>
                                <span className="tooltip-content">{cg_text}</span>
                            </a>
                            is <span className='box-list-item success'>{parseFloat(this.props.current_cloudguardscore_workload.toFixed(2))}%</span>
                            {
                                this.props.cloudguard_findings_critical > 0 &&
                                <span className='box-list-item danger space'>{this.props.cloudguard_findings_critical} Critical</span>
                            }
                            {
                                this.props.cloudguard_findings_high > 0 &&
                                <span className='box-list-item danger space'>{this.props.cloudguard_findings_high} High</span>
                            }
                        </div>
                    }
                    {
                        this.props.current_cloudguardscore_workload < 95 &&
                        <div>
                            <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                                <span className='box-list-item danger'>Current</span>
                                <span className="tooltip-content">{cg_text}</span>
                            </a>
                            is <span className='box-list-item danger'>{parseFloat(this.props.current_cloudguardscore_workload.toFixed(2))}%</span>
                            {
                                this.props.cloudguard_findings_critical > 0 &&
                                <span className='box-list-item danger space'>{this.props.cloudguard_findings_critical} Critical</span>
                            }
                            {
                                this.props.cloudguard_findings_high > 0 &&
                                <span className='box-list-item danger space'>{this.props.cloudguard_findings_high} High</span>
                            }
                        </div>
                    }
                    {
                        <div>LoD compliance score not archived.</div>
                    }
                    {
                        this.props.preview_cloudguardscore_workload >= 95 &&
                        this.props.preview_cloudguardscore_workload !== 0 &&
                        //Must be updated in view as preview should not be visible if not exist 
                        <div>
                            <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                                <span className='box-list-item general'>Preview</span>
                                <span className="tooltip-content">{cg_preview_text}</span>
                            </a>
                            is <span className='box-list-item success'>{parseFloat(this.props.preview_cloudguardscore_workload.toFixed(2))}%</span></div>
                    }
                    {
                        this.props.preview_cloudguardscore_workload < 95 &&
                        this.props.preview_cloudguardscore_workload !== 0 &&
                        //Must be updated in view as preview should not be visible if not exist 
                        <div><a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                            <span className='box-list-item general'>Preview</span>
                            <span className="tooltip-content">{cg_preview_text}</span>
                        </a>
                            is <span className='box-list-item danger'>{parseFloat(this.props.preview_cloudguardscore_workload.toFixed(2))}%</span></div>
                    }
                    {
                        !(this.props.cloudguard_findings_critical > 0 ||
                            this.props.cloudguard_findings_high > 0) &&
                        <div>No critical or high findings identifies.</div>
                    }
                    <NoteBox resp='DEV' acc='PO' con='GRC' text='Analyse/resolve unassessed findings.'><div><span className='box-list-item danger'>{this.props.cloudguard_findings_high} High</span> <span className='box-list-item info'>{this.props.cloudguard_findings_medium} Medium</span> <span className='box-list-item general'>{this.props.cloudguard_findings_low} Low</span></div></NoteBox>

                </div>
            }
            {
                this.props.category === "Security Compliant" &&
                <div className='tableCell divCompliant'>
                    <div><span className='box-list-item success'>Current</span> is <span className='box-list-item success'>{parseFloat(this.props.current_cloudguardscore_workload.toFixed(2))}%</span></div>
                    {
                        this.props.preview_cloudguardscore_workload >= 95 &&
                        this.props.preview_cloudguardscore_workload !== 0 &&
                        //Must be updated in view as preview should not be visible if not exist 
                        <div><span className='box-list-item general'>Preview</span> is <span className='box-list-item success'>{parseFloat(this.props.preview_cloudguardscore_workload.toFixed(2))}%</span></div>
                    }
                    {
                        this.props.preview_cloudguardscore_workload < 95 &&
                        this.props.preview_cloudguardscore_workload !== 0 &&
                        //Must be updated in view as preview should not be visible if not exist 
                        <div><span className='box-list-item general'>Preview</span> is <span className='box-list-item danger'>{parseFloat(this.props.preview_cloudguardscore_workload.toFixed(2))}%</span></div>
                    }
                    <div>LoD compliance score archived.</div>
                    <div><span className='box-list-item info'>{this.props.cloudguard_findings_medium} Medium</span> <span className='box-list-item general'>{this.props.cloudguard_findings_low} Low</span></div>
                </div>
            }
            {
                this.props.category === "Incomplete Data" &&
                this.props.children &&
                <div className='tableCell divNoData'>
                    <div><span className='box-list-item info'>{this.props.box}</span></div>
                    <div>{this.props.children}</div>
                    <NoteBox resp='DEV' acc='PO' con='GRC' text={this.props.resp_text}></NoteBox>
                </div>
            }
            {
                this.props.category === "Compliant" &&
                this.props.children &&
                <div className='tableCell divCompliant'>
                    <div><span className='box-list-item success'>{this.props.box}</span></div>
                    <div>{this.props.children}</div>
                </div>
            }
            {
                this.props.category === "Not Compliant" &&
                this.props.children &&
                <div className='tableCell divNotCompliant'>
                    <div><span className='box-list-item danger'>{this.props.box}</span></div>
                    <div>{this.props.children}</div>
                    {
                        this.props.resp_text &&
                        <NoteBox resp='DEV' acc='PO' con='GRC' text={this.props.resp_text}></NoteBox>
                    }
                    {
                        this.props.details &&
                        this.props.details
                    }
                </div>
            }
            {
                this.props.category === "General" &&
                this.props.children &&
                <div className='tableCell divNA'>
                    {
                        this.props.box &&
                        <div><span className='box-list-item general'>{this.props.box}</span></div>
                    }
                    {this.props.children}
                </div>
            }
            {
                this.props.category === "Release Compliant" &&
                <section className='container'>
                    <div className='one'>
                        <div><span className='box-list-item success'>Compliant</span></div>
                    </div>
                    <div className='two'><img className='badge_right' src={badge_release} alt="Batch Release" /></div>
                    <div>{this.props.children}</div>
                </section>
            }
        </>
    }
}

export default TableCell