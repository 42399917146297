import React from 'react';
import { useEffect, useState } from "react";
import { CloudGuardDetail, CloudGuardFinding } from "../../models/CloudGuardDetail";
import Legend from "../../components/table/Legend";
import cg_records from '../../data/19_2_1_grc_dashboard_cg_view.json';
import NoteBoxSmall from "../../components/table/NoteBoxSmall";
import useModal from "../../components/modal/useModal";
import export_time from '../../data/creation-dates.json';

function OtherFindings() {
    const records = cg_records as CloudGuardFinding[];

    const { isOpen, toggle } = useModal();
    const [data, setData] = useState("");
    const [cloudGuardDetails, setCloudGuardDetails] = useState<CloudGuardDetail[]>([]);
    const [filteredFindings, setFilteredFindings] = useState<CloudGuardFinding[]>([]);
    const [allFindings, setAllFindings] = useState<CloudGuardFinding[]>([]);

    const [rule, setRule] = useState<string[]>([])
    const [selectedRule, setSelectedRule] = useState("")

    const [findingtype, setFindingtype] = useState<string[]>([])
    const [selectedFindingtype, setSelectedFindingtype] = useState("")
    const [categories, setCategories] = useState<string[]>([])
    const [selectedCategory, setSelectedCategory] = useState("")
    const [countries, setCountries] = useState<string[]>([])
    const [selectedCountry, setSelectedCountry] = useState("")

    const UPDATETIME: string = export_time['19_2_1_grc_dashboard_cg_view_json'];

    const headers: string[] = [
        "Type",
        "Ruleset",
        "Rule Id",
        "Description",
        "Production total"
    ]

    async function loadData(searchText: string,
        category: string,
        country: string,
        type: string,
        ruleid: string) {
        if (records !== undefined) {
            setAllFindings(records);
            setCategories(records.filter((x, i, arr) => arr.findIndex(t => t.category && t.category !== "-" && (t.category === x.category)) === i).map(x => x.category));
            setCountries(records.filter((x, i, arr) => arr.findIndex(t => t.country && t.country !== "-" && (t.country === x.country)) === i).map(x => x.country));
            setFindingtype(records.filter((x, i, arr) => arr.findIndex(t => t.type && t.type !== "-" && (t.type === x.type)) === i).map(x => x.type));
            setRule(records.filter((x, i, arr) => arr.findIndex(t => t.ruleid && (t.ruleid === x.ruleid)) === i).map(x => x.ruleid));

            let filteredAndAggregated = aggregateFindings(records.filter(
                x => (searchText === '' || (x.application.toLowerCase().indexOf(searchText.toLowerCase()) > -1)) &&
                    (category === '' || x.category === category) &&
                    (country === '' || x.country === country) &&
                    (type === '' || x.type === type) &&
                    (ruleid === '' || x.ruleid === ruleid)));
            setCloudGuardDetails(filteredAndAggregated);
        }
    }

    useEffect(() => { // Initial load
        loadData("", "", "", "", "");
    }, []) // [] required so that useEffect is called once

    function aggregateFindings(findings: CloudGuardFinding[]): CloudGuardDetail[] {
        let result: CloudGuardDetail[] = []

        findings.forEach(finding => {
            let aggregatedFinding = result.filter(x => x.ruleid === finding.ruleid);
            if (aggregatedFinding.length === 0) {
                result.push({
                    ruleset: finding.ruleset,
                    type: finding.type,
                    ruleid: finding.ruleid,
                    severity: finding.severity,
                    description: finding.description,
                    category: finding.category,
                    country: finding.country,
                    prodAcc: 1
                })
            } else {
                aggregatedFinding[0].prodAcc++;
            }
        })
        setFilteredFindings(findings)
        return sortCloudGuardDetails(result);
    }

    function sortCloudGuardDetails(arr: CloudGuardDetail[]): CloudGuardDetail[] {
        const priorityOrder = ["High", "Medium", "Low"];
        arr.sort((a, b) => {
            const priorityA = priorityOrder.indexOf(a.severity);
            const priorityB = priorityOrder.indexOf(b.severity);

            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            } else {
                return b.prodAcc - a.prodAcc; // DESC, reverse if want ASC
            }
        });
        return arr;
    }

    function showFindings(securityRuleID: string) {
        setFilteredFindings(allFindings.filter(x => (x.ruleid === selectedRule)));
        if (selectedRule === securityRuleID) {
            securityRuleID = "";
            setFilteredFindings(allFindings)
        }
        setSelectedRule(securityRuleID);
        loadData("", "", "", "", securityRuleID);
        toggle();
    }

    function onSelectCategory(category: string) {
        if (selectedCategory === category) {
            category = "";
        }
        setSelectedCategory(category);
        setSelectedRule("");
        loadData("", category, selectedCountry, selectedFindingtype, selectedRule);
    }

    function onSelectCountry(country: string) {
        if (selectedCountry === country) {
            country = "";
        }
        setSelectedCountry(country);
        setSelectedRule("");
        loadData("", selectedCategory, country, selectedFindingtype, selectedRule);
    }

    function onSelectFindingtype(type: string) {
        if (selectedFindingtype === type) {
            type = "";
        }
        setSelectedFindingtype(type);
        setSelectedRule("");
        loadData("", selectedCategory, selectedCountry, type, selectedRule);
    }

    function handleSearch(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        loadData(data, selectedCategory, selectedCountry, selectedFindingtype, selectedRule);
    };

    function handleClear(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setData('')
        setSelectedRule("");
        setSelectedCountry("");
        loadData('', '', '', '', '');
    }

    function updateData(e: React.ChangeEvent<HTMLInputElement>) {
        setData(
            e.target.value
            // TODO how to put here object using Enum as properties? -> simple, useState<ObjectType>()
            //     {
            //     ...data,
            //     [e.target.name]: e.target.value
            // }
        )
        loadData(data, selectedCategory, selectedCountry, '', selectedRule);
        if (e.target.value === "") {
            setData('');
            setSelectedCategory("");
            setSelectedCountry("");
            setSelectedFindingtype("");
            setSelectedRule("");
            loadData('', '', '', '', '');
        }
    }

    return (
        <>
            <div className="filter-list">
                {
                    findingtype.map((x, index) => (
                        <div key={index}
                            onClick={() => onSelectFindingtype(x)}
                            className={x === selectedFindingtype ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                            {x}
                        </div>
                    ))
                }
            </div>
            <br />
            <div className="filter-list">
                {
                    categories.map((x, index) => (
                        <div key={index}
                            onClick={() => onSelectCategory(x)}
                            className={x === selectedCategory ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                            {x}
                        </div>
                    ))
                }
            </div>
            <br />
            <div className="filter-list">
                {
                    countries.map((x, index) => (
                        <div key={index}
                            onClick={() => onSelectCountry(x)}
                            className={x === selectedCountry ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                            {x}
                        </div>
                    ))
                }
            </div>
            <div className="btn-group">
                <input className='label' placeholder="Application Name" type="text" onChange={updateData} value={data}></input>
            </div>
            <Legend updatetime={UPDATETIME}></Legend>
            <table className="table-finding">
                <thead>
                    <tr>
                        {
                            headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        cloudGuardDetails.map((cg, index) => (
                            <tr key={index}>
                                <td>{cg.type}</td>
                                <td><span className="box-list-item danger">{cg.ruleset}</span></td>
                                <td>{cg.ruleid}</td>
                                <td className="left">{cg.description}</td>
                                <td>
                                    <div className='countCell item-select'>
                                        <span
                                            onClick={() => showFindings(cg.ruleid)}
                                            className={cg.severity === "High" ? "box-list-item danger" : (cg.severity === "Medium" ? "box-list-item info" : "box-list-item general")}>
                                            {cg.prodAcc} {cg.severity}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <table className="table-finding">
                <thead>
                    <tr>
                        <th>Application</th>
                        <th>Tenant</th>
                        <th>SNS Ticket</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredFindings.map((finding, index) => (
                            <tr key={index}>
                                <td>{finding.application}</td>
                                <td>{finding.tenant}</td>
                                <td><a href={`https://jira.platform.vwfs.io/browse/${finding.jirakey}`}>{finding.jirakey.slice(7)}</a></td>
                                {
                                    finding.description.includes("MPS privacy tags") &&
                                    <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC, ITS & BFT' text='Analyse finding and add MPS privacy tags.'></NoteBoxSmall><a href="https://csg.vwfs.io/sns/">DD&M SNS Documentation</a></div>
                                }
                                {
                                    finding.description.includes("CMK") &&
                                    <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC, ITS & BFT' text='Analyse finding and add MPS privacy tags.'>Documentation: <a href="https://docs.platform.vwfs.io/grc/aws/mps-encryption-policy/">MPS Encrytion Policy</a>, <a href="https://csg.vwfs.io/sns/">DD&M SNS</a></NoteBoxSmall></div>
                                }
                                {
                                    finding.description.includes("Backup") &&
                                    <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC, ITS & BFT' text='Analyse finding regarding VWFS I-OHB Guideline G07.G02 - Data Backup and Restore.'>Documentation: <a href="https://docs.platform.vwfs.io/grc/aws/mps-backup-policy/">MPS Backup Policy</a>, <a href="https://csg.vwfs.io/sns/">DD&M SNS</a></NoteBoxSmall></div>
                                }
                                {
                                    !finding.description.includes("Backup") &&
                                    !finding.description.includes("CMK") &&
                                    !finding.description.includes("MPS privacy tags") &&
                                    <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC, ITS & BFT' text='Analyse and close finding.'>Documentation: <a href="https://csg.vwfs.io/sns/">DD&M SNS</a></NoteBoxSmall></div>
                                }

                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default OtherFindings;