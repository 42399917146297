import React from 'react';
import { useEffect, useState } from "react";
import { ComplianceDetail, ComplianceDetailFinding } from "../../models/ComplianceDetail";
import Legend from "../../components/table/Legend";
import risk_records from '../../data/19_4_grc_dashboard_compliance_view.json';
import NoteBoxSmall from "../../components/table/NoteBoxSmall";
import useModal from "../../components/modal/useModal";
import export_time from '../../data/creation-dates.json';
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

export type MyParams = {
    id: string;
};

function DetailsRisk() {
    const records = risk_records as ComplianceDetailFinding[];

    const { id } = useParams<keyof MyParams>() as MyParams;
    let [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState("");
    const [filteredFindings, setFilteredFindings] = useState<ComplianceDetailFinding[]>([]);
    const [selectedApp, setselectedApp] = useState("")

    const UPDATETIME: string = export_time['19_4_grc_dashboard_compliance_view_json'];

    const headers: string[] = [
        "Category",
        "Process",
        "Process Name",
        "Control",
        "Control Type",
        "Description",
        "Application total"
    ]

    async function loadData(searchText: string) {
        if (records !== undefined) {
            let filteredAndAggregated = records.filter(x => x.finding !== 0 && (searchText === '' || (x.application.toLowerCase().indexOf(searchText.toLowerCase()) > -1)))
            setFilteredFindings(filteredAndAggregated);
            setselectedApp(searchText);
        }
    }

    useEffect(() => { // Initial load
        const id = searchParams.get("appname")
        if (id) {
            loadData(id);
        } else {
            loadData('');
        }
    }, []) // [] required so that useEffect is called once

    function updateData(e: React.ChangeEvent<HTMLInputElement>) {
        setData(
            e.target.value
            // TODO how to put here object using Enum as properties? -> simple, useState<ObjectType>()
            //     {
            //     ...data,
            //     [e.target.name]: e.target.value
            // }
        )
        loadData(data);
        if (e.target.value === "") {
            setData('')
            loadData('');
        }
    }

    return (
        <>
            <br />

            <Legend updatetime={UPDATETIME}></Legend>
            {
                selectedApp &&
                <h1>Detected Findings - {selectedApp}</h1>
            }
            {
                !selectedApp &&
                <h1>Detected Findings</h1>
            }
            <div className="btn-group">
                <input className='label' placeholder="Application Name" type="text" onChange={updateData} value={data}></input>
            </div>
            <table className="table-finding">
                <thead>
                    <tr>
                        <th>Application Name</th>
                        <th>Process</th>
                        <th>Control ID</th>
                        <th>Control Type</th>
                        <th>Control Name</th>
                        <th>Result</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredFindings.map((finding, index) => (
                            <tr key={index}>
                                <td style={{ width: "40em" }}>{finding.application}</td>
                                <td style={{ width: "7em" }}>{finding.process}</td>
                                <td style={{ width: "7em" }}>{finding.control}</td>
                                <td style={{ width: "7em" }}>{finding.control_type}</td>
                                <td style={{ width: "20em" }}>{finding.controlname}</td>
                                <td style={{ width: "10em" }}>
                                    {
                                        finding.finding === 0 &&
                                        <span className="box-list-item success">Compliant</span>
                                    }
                                    {
                                        finding.finding !== 0 &&
                                        <span className="box-list-item danger">Not Compliant</span>
                                    }
                                </td>
                                <td style={{ width: "50em" }}>
                                    {
                                        finding.finding !== 0 &&
                                        <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC' text='Finding must be closed.'>Documentation: <a href="https://csg.vwfs.io/general/itprocesshouse/#internal-control-system">Internal Control System</a></NoteBoxSmall></div>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default DetailsRisk;