import React from 'react';
import { ReleaseTimelapse } from '../../models/ReleaseTimelapse';
import timelapse_records from '../../data/release-status-timelapse.json';
import export_time from '../../data/creation-dates.json';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    // ChartDataLabels,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function ReleaseTimelapseReport() {
    const UPDATETIME: string = export_time['release-status-timelapse_json'];

    function aggregateDataPerMonth(records: ReleaseTimelapse[]): Map<string, Map<string, number>> {
        let aggregated: Map<string, Map<string, number>> = new Map<string, Map<string, number>>();
        records = sortRiskDetails(records)
        records.forEach(element => {
            if (aggregated.has(element.Status)) {
                let byStatus = aggregated.get(element.Status);
                if (byStatus!.has(element.Month)) {
                    let currentMonthHours = byStatus!.get(element.Month);
                    if (currentMonthHours !== undefined) {
                        byStatus!.set(element.Month, Math.round((currentMonthHours + (+element.TimelapseHours)) * 100) / 100);
                    }
                } else {
                    byStatus!.set(element.Month, +element.TimelapseHours);
                }
            } else {
                aggregated.set(element.Status, new Map<string, number>([[element.Month, +element.TimelapseHours]]))
            }
        });
        return aggregated;
    }

    function sortRiskDetails(arr: ReleaseTimelapse[]): ReleaseTimelapse[] {
        const priorityOrder = ["Decision on Change Type", "Fill in Self-Assessment", "In Solution Design", 
        "Waiting for BFT Solution Design approval", "Waiting for ITS Solution Design approval",
        "Waiting for Categorization approval", "Definition done", "In Development", "Waiting for Business Go-Live Approval",
        "Waiting for Business Acceptance", "Waiting for IT Go-Live Approval", "Waiting for IT Acceptance", "Deployment approved",
        "Deployment accepted"];
        arr.sort((a, b) => {
            const priorityA = priorityOrder.indexOf(a.Status);
            const priorityB = priorityOrder.indexOf(b.Status);

            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            } else {
                return priorityB - priorityA; // DESC, reverse if want ASC
            }
        });
        return arr;
    }

    let aggregatedRecords = aggregateDataPerMonth(timelapse_records);

    function buildDatasets(data: Map<string, Map<string, number>>) {
        let result: any[] = []
        data.forEach((value, key) => {

            result.push({
                label: key,
                data: [
                    value.has("1") ? value.get("1") : 0,
                    value.has("2") ? value.get("2") : 0,
                    value.has("3") ? value.get("3") : 0,
                    value.has("4") ? value.get("4") : 0,
                    value.has("5") ? value.get("5") : 0,
                    value.has("6") ? value.get("6") : 0,
                    value.has("7") ? value.get("7") : 0,
                    value.has("8") ? value.get("8") : 0,
                    value.has("9") ? value.get("9") : 0,
                    value.has("10") ? value.get("10") : 0,
                    value.has("11") ? value.get("11") : 0,
                    value.has("12") ? value.get("12") : 0,
                ],
                backgroundColor: getColorForStatus(key)
            })
        });
        return result;
    }

    function getColorForStatus(key: string): string {
        var color = "green";
        if(key === "Decision on Change Type"){
            color = "#B3ECFF";
        }
        else if(key === "Fill in Self-Assessment"){
            color = "#B3ECFF";
        }
        else if(key === "In Solution Design"){
            color = "#325D72"
        }
        else if(key === "Waiting for BFT Solution Design approval"){
            color = "#4A8BAC"
        }
        else if(key === "Waiting for ITS Solution Design approval"){
            color = "#B3D0DE"
        }
        else if(key === "Waiting for Categorization approval"){
            color = "#B3D0DE"
        }
        else if(key === "Definition done"){
            color = "#CE4684"
        }
        else if(key === "In Development"){
            color = "#5F1939"
        }
        else if(key === "Waiting for Business Go-Live Approval"){
            color = "#5CCCFF"
        }
        else if(key === "Waiting for Business Acceptance"){
            color = "#5CCCFF"
        }
        else if(key === "Waiting for IT Go-Live Approval"){
            color = "#0AB2FF"
        }
        else if(key === "Waiting for IT Acceptance"){
            color = "#0AB2FF"
        }
        else if(key === "Deployment approved"){
            color = "#92D050"
        }
        else if(key === "Deployment accepted"){
            color = "#92D050"
        }
        else{
            color = "blue"
        }
        return color
    }

    const options = {
        plugins: {
            title: {
                display: true,
                maintainAspectRatio: false,
                text: '',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: '2023'
                }
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Total hours per status',
                    // color: 'red'
                }
            }
        },
        legend: {
            display: true,
            legendText: ['Current', 'Vs last week/month/year', '% Change']
        }
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const data = {
        labels,
        datasets: buildDatasets(aggregatedRecords)
    }

    // TODO might use 'ChartDataLabels' when some statuses are combined, now it looks ugly
    // return <Bar options={options} plugins={[ChartDataLabels]} data={data} />;
    return (
        <>
        <div><h2>Release - amount of time spent for different statuses</h2></div>
        <div className="divGeneral">Data aggregated as of <b>{UPDATETIME}</b>, source data might be older. </div>
        <div style={{minWidth:"100em", maxWidth:"180em", minHeight:"30em", maxHeight:"50em"}} >
            <Bar 
                options={options} 
                data={data} 
                height={80}  
            />
        </div>
        </>
    )
}

export default ReleaseTimelapseReport;