import React, { Component} from 'react';

class NoteBoxSmall extends Component<any, any> {

    render() {
        return <>
        <div>
            <span className='box-list-item note'>R</span> <b>{this.props.resp}</b> <span className='box-list-item general'>A</span> <b>{this.props.acc}</b> <span className='box-list-item general'>C</span> <b>{this.props.con}</b>
            → {this.props.text} <div>{this.props.children}</div>
        </div>
    </>
    }
}

export default NoteBoxSmall