import React from 'react';
import { useEffect, useState } from "react";
import { ComplianceDetail, ComplianceDetailFinding } from "../../models/ComplianceDetail";
import Legend from "../../components/table/Legend";
import risk_records from '../../data/19_4_grc_dashboard_compliance_view.json';
import NoteBoxSmall from "../../components/table/NoteBoxSmall";
import useModal from "../../components/modal/useModal";
import export_time from '../../data/creation-dates.json';
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import SumBox from "../../components/table/SumBox";

export type MyParams = {
    id: string;
};

function DetailsRisk() {
    const records = risk_records as ComplianceDetailFinding[];

    const { id } = useParams<keyof MyParams>() as MyParams;
    let [searchParams, setSearchParams] = useSearchParams();

    const { isOpen, toggle } = useModal();
    const [data, setData] = useState("");
    const [ComplianceDetails, setComplianceDetails] = useState<ComplianceDetail[]>([]);
    const [filteredFindings, setFilteredFindings] = useState<ComplianceDetailFinding[]>([]);
    const [allRiskFindings, setAllRiskFindings] = useState<ComplianceDetailFinding[]>([]);
    const [selectedControl, setSelectedControl] = useState("")

    const [categoriesProcess, setCategoriesProcess] = useState<string[]>([])
    const [selectedProcess, setselectedProcess] = useState("")
    const [selectedProcessName, setselectedProcessName] = useState<string[]>([])

    const UPDATETIME: string = export_time['19_4_grc_dashboard_compliance_view_json'];

    const headers: string[] = [
        "Category",
        "Process",
        "Process Name",
        "Control",
        "Control Type",
        "Description",
        "Application total"
    ]

    async function loadData(searchText: string, process: string, control: string) {
        if (records !== undefined) {
            setAllRiskFindings(records);
            setCategoriesProcess(records.filter((x, i, arr) => arr.findIndex(
                t => t.process && (t.process === x.process)) === i).map(x => x.process));

            let filteredAndAggregated = aggregateFindings(records.filter(
                x => (process === '' || x.process === process) && (control === '' || x.control === control) && (searchText === '' || (x.application.toLowerCase().indexOf(searchText.toLowerCase()) > -1))));
            setComplianceDetails(sortComplianceDetails(filteredAndAggregated));
        }
    }

    useEffect(() => { // Initial load
        const id = searchParams.get("appname")
        if (id) {
            loadData(id, '', '');
        } else {
            loadData('', '', '');
        }
    }, []) // [] required so that useEffect is called once

    function aggregateFindings(findings: ComplianceDetailFinding[]): ComplianceDetail[] {
        let result: ComplianceDetail[] = []

        findings.forEach(finding => {
            let aggregatedFinding = result.filter(x => x.process === finding.process && x.control === finding.control);
            if (aggregatedFinding.length === 0) {
                if (finding.finding === 1) {
                    result.push({
                        process: finding.process,
                        processname: finding.processname,
                        category: finding.category,
                        control: finding.control,
                        controlname: finding.controlname,
                        control_type: finding.control_type,
                        prodAcc_noncomp: 1,
                        prodAcc_comp: 0,
                    })
                } else if (finding.finding === 0) {
                    result.push({
                        process: finding.process,
                        processname: finding.processname,
                        category: finding.category,
                        control: finding.control,
                        controlname: finding.controlname,
                        control_type: finding.control_type,
                        prodAcc_noncomp: 0,
                        prodAcc_comp: 1,
                    })
                }
            } else {
                if (finding.finding === 1) {
                    aggregatedFinding[0].prodAcc_noncomp++;
                } else if (finding.finding === 0) {
                    aggregatedFinding[0].prodAcc_comp++;
                }
            }
        })
        setFilteredFindings(sortComplianceDetails(findings.filter(x => x.finding !== 0)))
        return sortComplianceDetails(result);
    }

    function sortComplianceDetails(arr: any[]): any[] {
        const priorityOrder = ["Governance", "Build", "Run"];
        arr.sort((a, b) => {
            const priorityA = priorityOrder.indexOf(a.category);
            const priorityB = priorityOrder.indexOf(b.category);

            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            } else if (a.control.toLowerCase() < b.control.toLowerCase()) {
                return -1
            } else if (a.control.toLowerCase() > b.control.toLowerCase()) {
                return 1
            } else {
                return b.finding - a.finding; // DESC, reverse if want ASC
            }
        });
        return arr;
    }

    function showFindings(complianceProcess: string, complianceControl: string) {

        setFilteredFindings(sortComplianceDetails(allRiskFindings.filter(x => (!selectedControl || x.control === complianceControl)
            && (!selectedProcess || x.process === selectedProcess) &&
            x.control === complianceControl)));
        if (selectedControl === complianceControl) {
            setFilteredFindings(sortComplianceDetails(allRiskFindings))
            complianceControl = "";
            complianceProcess = "";
        }
        setSelectedControl(complianceControl)
        setselectedProcess(complianceProcess)
        loadData("", complianceProcess, complianceControl);
        toggle();
    }

    function onSelectprocess(process: string) {
        if (selectedProcess === process) {
            process = "";
        }
        setSelectedControl("");
        setselectedProcess(process);
        setselectedProcessName(allRiskFindings.filter(x => (!selectedProcess || x.process === process)).map(x => x.processname).slice(0, 1))
        loadData("", process, "");
    }

    function getProcessName(p:string){
        let processname = records.filter(cd => cd.process ===p).map(cd => cd.processname)[0]
        return processname
    }

    return (
        <>
            {
                !selectedProcess &&
                <h1>Compliance Monitoring</h1>
            }
            {
                selectedProcess &&
                <h1>{selectedProcess} | {selectedProcessName}</h1>
            }
            <br />
            <div className="filter-list">
                {
                    categoriesProcess.sort().map((x, index) => (
                        <div style={{ display: "flex" }}>
                            <div key={index}
                                onClick={() => onSelectprocess(x)}
                                className={"filter-process-item-sidebar"}
                                style = {{backgroundColor: `${x[0] === "G" ? '#4A8BAC' : x[0] === "B" ? '#5F1939': x[0] === "R" ? '#95A844'  : '#D8AA00'}`}}>
                                {x}
                            </div>
                            <div key={index}
                                onClick={() => onSelectprocess(x)}
                                className={x === selectedProcess ? "filter-process-item filter-process-item-selected" : "filter-process-item"}>
                                {getProcessName(x)}
                            </div>
                        </div>
                    ))
                }
            </div>
            <div style={{ display: "flex" }}>
                <SumBox size='l' color='#006384' number={ComplianceDetails.length} text='Controls implemented'></SumBox>
                <SumBox size='l' color='#5f1939' number={ComplianceDetails.filter(x => x.control_type === "automated").length} text='Controls automated'></SumBox>
                <SumBox size='l' color='#ff991f' number={filteredFindings.length} text='Findings Detected'></SumBox>
            </div>
            <Legend updatetime={UPDATETIME}></Legend>
            <h3>Control Overview</h3>
            <table className="table-finding">
                <thead>
                    <tr>
                        {
                            headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        ComplianceDetails.map((rk, index) => (
                            <tr key={index}>
                                <td style={{ width: "10em" }}>{rk.category}</td>
                                <td style={{ width: "7em" }}>{rk.process}</td>
                                <td style={{ width: "30em" }}>{rk.processname}</td>
                                <td style={{ width: "10em" }}>{rk.control}</td>
                                <td style={{ width: "10em" }}>{rk.control_type}</td>
                                <td style={{ width: "50em" }}>{rk.controlname}</td>
                                <td style={{ width: "15em" }}>
                                    <div className='countCell item-select' >
                                        <span
                                            onClick={() => showFindings(rk.process, rk.control)}
                                            className={rk.prodAcc_comp / (rk.prodAcc_comp + rk.prodAcc_noncomp) === 0 ? "box-list-item danger" :
                                                rk.prodAcc_comp / (rk.prodAcc_comp + rk.prodAcc_noncomp) < 1 ? "box-list-item info" : "box-list-item success"}>
                                            {parseFloat((rk.prodAcc_comp / (rk.prodAcc_comp + rk.prodAcc_noncomp) * 100).toFixed(0))}
                                            {"% Compliant"}
                                        </span>
                                    </div>

                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <h3>Detected Findings</h3>
            <table className="table-finding">
                <thead>
                    <tr>
                        <th>Application Name</th>
                        <th>Process</th>
                        <th>Control</th>
                        <th>Result</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredFindings.map((finding, index) => (
                            <tr key={index}>
                                <td style={{ width: "40em" }}>{finding.application}</td>
                                <td style={{ width: "7em" }}>{finding.process}</td>
                                <td style={{ width: "7em" }}>{finding.control}</td>
                                <td style={{ width: "10em" }}>
                                    {
                                        finding.finding === 0 &&
                                        <span className="box-list-item success">Compliant</span>
                                    }
                                    {
                                        finding.finding !== 0 &&
                                        <span className="box-list-item danger">Not Compliant</span>
                                    }
                                </td>
                                <td style={{ width: "50em" }}>
                                    {
                                        finding.finding !== 0 &&
                                        <div><NoteBoxSmall resp='DEV' acc='PO' con='GRC' text='Finding must be closed.'>Documentation: <a href="https://csg.vwfs.io/general/itprocesshouse/#internal-control-system">Internal Control System</a></NoteBoxSmall></div>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default DetailsRisk;