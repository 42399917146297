import React from 'react';
import { useEffect, useState } from "react";
import { ReleasePlan } from "../../models/ReleasePlan";
import Legend from "../../components/table/Legend";
import release_records from '../../data/19_6_grc_dashboard_releaseplan_view.json';
import icon_grel from "../../icons/GREL.png"
import icon_normal from "../../icons/Release_Normal.png"
import icon_minor from "../../icons/Release_Minor.png"
import icon_bugfix from "../../icons/Release_Bugfix.png"
import icon_hotfix from "../../icons/Release_Hotfix.png"
import export_time from '../../data/creation-dates.json';
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import SumBox from "../../components/table/SumBox";

export type MyParams = {
    id: string;
};

interface DateConstructor {
    startDate: Date;
}

function DetailsRisk() {
    const records = release_records as ReleasePlan[];

    const { id } = useParams<keyof MyParams>() as MyParams;
    let [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState("");
    const [releases, setReleases] = useState<ReleasePlan[]>([]);
    const [selectedApp, setselectedApp] = useState("")
    const [selectedPeriod, setselectedPeriod] = useState(Number)
    const [selectedPNA, setselectedPNA] = useState("")
    const [selectedBIA, setselectedBIA] = useState("")

    const UPDATETIME: string = export_time['19_4_grc_dashboard_compliance_view_json'];

    const headers: string[] = [
        "Category",
        "Process",
        "Process Name",
        "Control",
        "Control Type",
        "Description",
        "Application total"
    ]

    async function loadData(searchText: string, period: number, pna: string, bia: string) {
        if (records !== undefined) {
            // remove period in case already selected
            if (selectedPeriod === period) {
                period = 0;
            }
            if (selectedPNA === pna) {
                pna = '';
            }
            if (selectedBIA === bia) {
                bia = '';
            }
            // filter for application
            let filteredAndAggregated = records.filter(x => (searchText === '' ||
                (x.alias.toLowerCase().indexOf(searchText.toLowerCase()) > -1)))
            //filter for period
            filteredAndAggregated = filteredAndAggregated.filter(x => period === 0 ||
                (new Date(x.plannedgolivedate) <= new Date(new Date().getTime() + (period * 24 * 60 * 60 * 1000)) && new Date(x.plannedgolivedate) >= new Date()))
            // filter on pna and bia
            filteredAndAggregated = filteredAndAggregated.filter(x => (pna === '' || x.pna === pna) && (bia === '' || x.bia === bia))
            // sort on plannedgolivedate
            filteredAndAggregated = filteredAndAggregated.sort(function (a, b) {
                // Convert the date strings to Date objects
                let dateA = new Date(a.plannedgolivedate).valueOf();
                let dateB = new Date(b.plannedgolivedate).valueOf();

                // Subtract the dates to get a value that is either negative, positive, or zero
                return dateA - dateB;
            });
            setReleases(filteredAndAggregated);
            setselectedApp(searchText);
            setselectedPeriod(period);
            setselectedPNA(pna);
            setselectedBIA(bia);
        }
    }

    useEffect(() => { // Initial load
        const id = searchParams.get("appname")
        if (id) {
            loadData(id, 0, '', '');
        } else {
            loadData('', 0, '', '');
        }
    }, []) // [] required so that useEffect is called once

    function updateData(e: React.ChangeEvent<HTMLInputElement>) {
        setData(
            e.target.value
            // TODO how to put here object using Enum as properties? -> simple, useState<ObjectType>()
            //     {
            //     ...data,
            //     [e.target.name]: e.target.value
            // }
        )
        loadData(data, 0, selectedPNA, selectedBIA);
        if (e.target.value === "") {
            setData('')
            loadData('', 0, selectedPNA, selectedBIA);
        }
    }

    return (
        <>
            <br />
            <Legend updatetime={UPDATETIME}></Legend>
            <h1>Filter</h1>
            <div className="btn-group">
                <input className='label' placeholder="Application Name" type="text" onChange={updateData} value={data}></input>
            </div>
            <br />
            <div className="filter-list">
                <div
                    onClick={() => loadData(selectedApp, selectedPeriod, selectedPNA, "Yes")}
                    className={selectedBIA === "Yes" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    BIA critical
                </div>
            </div>
            <div className="filter-list">
                <div
                    onClick={() => loadData(selectedApp, selectedPeriod, "A", selectedBIA)}
                    className={selectedPNA === "A" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    PNA: A
                </div>
            </div>
            <div className="filter-list">
                <div
                    onClick={() => loadData(selectedApp, selectedPeriod, "B", selectedBIA)}
                    className={selectedPNA === "B" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    PNA: B
                </div>
            </div>
            <div className="filter-list">
                <div
                    onClick={() => loadData(selectedApp, selectedPeriod, "C", selectedBIA)}
                    className={selectedPNA === "C" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    PNA: C
                </div>
            </div>
            <br />
            <div className="filter-list">
                <div
                    onClick={() => loadData(selectedApp, 7, selectedPNA, selectedBIA)}
                    className={selectedPeriod === 7 ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    Next week
                </div>
                <div
                    onClick={() => loadData(selectedApp, 30, selectedPNA, selectedBIA)}
                    className={selectedPeriod === 30 ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    Next month
                </div>
                <div
                    onClick={() => loadData(selectedApp, 90, selectedPNA, selectedBIA)}
                    className={selectedPeriod === 90 ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    Next 3 month
                </div>
                <div
                    onClick={() => loadData(selectedApp, 365, selectedPNA, selectedBIA)}
                    className={selectedPeriod === 365 ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    Next year
                </div>
            </div>
            {
                selectedApp &&
                <h1>Release Plan {selectedApp}</h1>
            }
            {
                !selectedApp &&
                <h1>Release Plan</h1>
            }
            <div>Note: All releases that have not yet been deployed and for which the planned go-live date is not older than 30 days are displayed. </div>
            <div style={{ display: "flex" }}>
                <SumBox size='l' color='#253858' number={releases.length} text='Releases' icon={icon_grel}></SumBox>
                <SumBox size='l' color='#006384' number={releases.filter(x => x.releasetype === "Normal").length} text='Normal Releases' icon={icon_normal}></SumBox>
                <SumBox size='l' color='#80b0c8' number={releases.filter(x => x.releasetype === "Minor Release").length} text='Minor Releases' icon={icon_minor}></SumBox>
                <SumBox size='l' color='#ce4684' number={releases.filter(x => x.releasetype === "Bugfix Release").length} text='Bugfix Releases' icon={icon_bugfix}></SumBox>
                <SumBox size='l' color='#5f1939' number={releases.filter(x => x.releasetype === "Hotfix Release").length} text='Hotfix Releases' icon={icon_hotfix}></SumBox>
            </div>
            <table className="table-finding">
                <thead>
                    <tr>
                        <th>Planned Go-Live Date</th>
                        <th>Application</th>
                        <th>LeanIX App ID</th>
                        <th>Criticality</th>
                        <th>Release - Release Type</th>
                        <th>Release - Risk Category</th>
                        <th>Release - Status</th>
                        <th>Release - Link</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        releases.map((release, index) => (
                            <tr key={index}>
                                <td style={{ width: "15em" }}>{release.plannedgolivedate.substring(0, 10)}</td>
                                <td style={{ width: "15em" }}>{release.alias}</td>
                                <td style={{ width: "15em" }}>{release.leanixappid}</td>
                                <td style={{ width: "15em" }}>{<div> {release.pna} <br /> {release.bia}</div>}</td>
                                <td style={{ width: "15em" }}>{release.releasetype}</td>
                                <td style={{ width: "15em" }}>{release.riskcategorization}</td>
                                <td style={{ width: "15em" }}>{release.status}</td>
                                <td style={{ width: "10em" }}><div><a href={`https://jira.platform.vwfs.io/browse/${release.jirakey}`}> {release.jirakey}</a></div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default DetailsRisk;