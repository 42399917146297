import React from 'react';
import { NavLink, Route, Routes } from "react-router-dom";
import ApplicationReport from "./reports/ApplicationReport";
import ControlReport from "./reports/ControlReport";
import ComplianceInsight from "./reports/ComplianceInsight";
import ComplianceAppView from "./reports/ControlAppView";
import RiskReport from "./reports/RiskReport";
import FindingsDetails from "./reports/FindingsDetails";
import ReleaseInsight from "./reports/ReleaseInsight";
import ReleasePlan from "./reports/ReleasePlan";
import OtherFindings from "./reports/OtherFindings";
import link_its from "../icons/ITSLinkIcon.png"
import ManagementSummary from './reports/ManagementSummary';
import ReleaseTimelapseReport from './reports/ReleaseTimelapseReport';

function HomePage() {
    return (
        <>
            <div className="main-container">
                <header className="header header-6" >
                    <div className="branding"><span className="title">
                        <b>VWFS AG Digital, Direct & Mobility Cloud Standards & Guidance - Insights</b></span>
                    </div>
                </header>
                <nav className="subnav">
                    <ul className="nav">
                        <li className="nav-item"><NavLink to="/" className="nav-link">Product Owner Summary</NavLink></li>
                        <li className="nav-item"><NavLink to="/management-summary" className="nav-link">Management Summary</NavLink></li>
                        <li className="nav-item"><NavLink to="/findings-details" className="nav-link">Security Insight</NavLink></li>
                        <li className="nav-item"><NavLink to="/compliance-insight" className="nav-link">Compliance Insight</NavLink></li>
                        <li className="nav-item"><NavLink to="/risk-report" className="nav-link">Risk Insight</NavLink></li>
                        <li className="nav-item"><NavLink to="/release-insight" className="nav-link">Release Insight</NavLink></li>
                        <a style={{ textDecoration: "none", color: "black", padding: "0px" }} href='https://dev.insight.standards.vwfs.io'>
                            <img style={{ maxHeight: "12.5px", maxWidth: "17.5px", paddingRight: "5px", paddingBottom: "0.11em" }} src={link_its} alt="Batch Release" />
                            <b>ITS View</b>
                        </a>
                        <span className='button-feedback'>
                            <a href='https://jira.platform.vwfs.io/servicedesk/customer/portal/37/create/503'>
                                <b>Feedback</b>
                            </a>
                        </span>
                    </ul>
                </nav>
                <div className="content-container">
                    <div className="content-area">
                        <Routes>
                            <Route path="/" element={<ApplicationReport />} />
                            <Route path="management-summary" element={<ManagementSummary />} />
                            <Route path="findings-details" element={<FindingsDetails />}>
                                <Route path="" element={<OtherFindings />} />
                            </Route>
                            <Route path="compliance-insight" element={<ComplianceInsight />}>
                                <Route path="" element={<ControlReport />} />
                                <Route path="control-appview" element={<ComplianceAppView />} />
                            </Route>
                            <Route path="/risk-report" element={<RiskReport />} />
                            <Route path="/release-insight" element={<ReleaseInsight />} >
                                <Route path="" element={<ReleasePlan />} />
                                <Route path="timelapse" element={<ReleaseTimelapseReport />} />
                            </Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage;