import React from 'react';
import { NavLink, Outlet } from "react-router-dom";

function FindingsDetails() {


    return (
        <>
            <div className="tabs-horizontal">
                <ul id="demoTabs" className="nav" role="tablist">
                    <li role="presentation" className="nav-item">
                        <NavLink end to="/findings-details" id="tab1" className="btn btn-link nav-link" aria-controls="panel1"
                            aria-selected="true" type="button">CloudGuard, GuardDuty & IAM Access Analyzer</NavLink>
                    </li>
                </ul>
            </div>
            <Outlet></Outlet>
        </>
    )
}

export default FindingsDetails;