import React from 'react';
import { useEffect, useState } from "react";
import { Management } from "../../models/Management";
import { Bar, Chart } from 'react-chartjs-2';
import SumBox from "../../components/table/SumBox";
import app_records from '../../data/19_5_grc_dashboard_management_view.json';
import icon_grel from "../../icons/GREL.png"
import icon_cloud from "../../icons/iconCloud.png"
import icon_cloudguard from "../../icons/CloudGuard.png"
import icon_pentest from "../../icons/Pentest.png"
import icon_vulnerscan from "../../icons/VulnerScan.png"
import icon_techreview from "../../icons/Techreview.png"

function ManagementSummary() {
    const records = app_records as Management[];
    const [managementDetails, setManagementDetails] = useState<Management[]>([]);
    const [cloudguardDetails, setCloudGuardDetails] = useState<Management[]>([]);
    const [yearsRecord, setYearsRecord] = useState<string[]>([])
    const [selectedYear, setSelectedYear] = useState("2024")
    const [greltotal, setGrelTotal] = useState<number>();
    const [grelnormaldepl, setGrelNormalDepl] = useState<number>();
    const [grelnormalnodepl, setGrelNormalNoDepl] = useState<number>();
    const [grelbugdepl, setGrelBugDepl] = useState<number>();
    const [grelbugnodepl, setGrelBugNoDepl] = useState<number>();
    const [grelminordepl, setGrelMinorDepl] = useState<number>();
    const [grelminornodepl, setGrelMinorNoDepl] = useState<number>();
    const [riskpentest, setRiskPentest] = useState<number>();
    const [riskpentestlow, setRiskPentestLow] = useState<number>();
    const [riskpentestmedium, setRiskPentestMedium] = useState<number>();
    const [riskpentesthigh, setRiskPentestHigh] = useState<number>();
    const [riskvulnerscan, setRiskVulnerScan] = useState<number>();
    const [riskvulnerscancritical, setRiskVulnerScanCritical] = useState<number>();
    const [riskvulnerscanhigh, setRiskVulnerScanHigh] = useState<number>();
    const [risktechreview, setRiskTechReview] = useState<number>();
    const [risktechreviewlow, setRiskTechReviewLow] = useState<number>();
    const [risktechreviewmedium, setRiskTechReviewMedium] = useState<number>();
    const [risktechreviewhigh, setRiskTechReviewHigh] = useState<number>();
    const [cghigh, setCGHigh] = useState<number>();
    const [cgmedium, setCGMedium] = useState<number>();
    const [cglow, setCGLow] = useState<number>();
    const [cgscore, setCGScore] = useState<number>();
    const [appNumber, setAppNumber] = useState<number>();
    const [appNumberA, setAppNumberA] = useState<number>();
    const [appNumberB, setAppNumberB] = useState<number>();
    const [appNumberC, setAppNumberC] = useState<number>();

    //Tooltips
    const tipGREL = 'Number of releases based on the Planned Go-Live Date.'
    const tipApps = 'Number of applications that are in scope of DD&M.'
    const tipCG = 'Results are aggregated across all applications of DD&M (productive environments).'
    const tipPT = 'Number of findings not marked as closed based on the creation date.'
    const tipVS = 'Number of findings not marked as closed based on the creation date.'
    const tipTR = 'Number of findings not marked as closed based on the creation date.'

    const headers: string[] = [
        "Country",
        "Number of Tenants",
        "Avg. Score (Prod)",
    ]

    var channelArray: Array<string> = ['jp', 'es', 'nl', 'br', 'se', 'mx', 'uk', 'fr', 'cz', 'pl', 'it', 'us', 'pt'];

    async function loadData() {
        let appNumber = 0
        let appNumberA = 0
        let appNumberB = 0
        let appNumberC = 0
        let greltotal = 0
        let appGRELNormalDepl = 0
        let appGRELNormalNoDepl = 0
        let appGRELBugDepl = 0
        let appGRELBugNoDepl = 0
        let appGRELMinorDepl = 0
        let appGRELMInorNoDepl = 0
        let appRiskPentest = 0
        let appRiskPentestLow = 0
        let appRiskPentestMedium = 0
        let appRiskPentestHigh = 0
        let appRiskVulnerScan = 0
        let appRiskVulnerScanLow = 0
        let appRiskVulnerScanCritical = 0
        let appRiskVulnerScanHigh = 0
        let appRiskTechReview = 0
        let appRiskTechReviewLow = 0
        let appRiskTechReviewMedium = 0
        let appRiskTechReviewHigh = 0
        let appCGHigh = 0
        let appCGMedium = 0
        let appCGLow = 0
        let appCGScore = 0

        setYearsRecord(records.filter((x, i, arr) => arr.findIndex(
            t => t.t_year && (t.t_year === x.t_year)) === i).map(x => x.t_year).sort(
                (n1, n2) => parseInt(n1) - parseInt(n2)));
        setManagementDetails(records)
        setCloudGuardDetails(records.filter(
            x => x.s_type === 'CloudGuard Int' && channelArray.includes(x.country)
        ).sort((a, b) => a.number < b.number ? 1 : -1))
        if (selectedYear !== '') {
            setManagementDetails(records.filter(x => (x.t_year === selectedYear || x.t_year === '')))
        }

        managementDetails.forEach(app => {
            switch (app['s_type']) {
                case 'Application':
                    appNumber += parseInt(app['number']);
                    if (app['risklevel'] === 'A') {
                        appNumberA += parseInt(app['number']);
                    } else if (app['risklevel'] === 'B') {
                        appNumberB += parseInt(app['number']);
                    } else if (app['risklevel'] === 'C') {
                        appNumberC += parseInt(app['number']);
                    }
                    break;
                case 'CloudGuard Findings':
                    if (app['risklevel'] === 'High') {
                        appCGHigh += parseInt(app['number'])
                    } else if (app['risklevel'] === 'Medium') {
                        appCGMedium += parseInt(app['number'])
                    } else if (app['risklevel'] === 'Low') {
                        appCGLow += parseInt(app['number'])
                    }
                    break;
                case 'Normal Release':
                    if (app['t_year'] !== '') {
                        greltotal += parseInt(app['number'])
                        if (app['status'] === 'Deployed') {
                            appGRELNormalDepl += parseInt(app['number'])
                        } else if (app['status'] === 'In Progress') {
                            appGRELNormalNoDepl += parseInt(app['number'])
                        }
                    }
                    break;
                case 'Bugfix Release':
                    if (app['t_year'] !== '') {
                        greltotal += parseInt(app['number'])
                        if (app['status'] === 'Deployed') {
                            appGRELBugDepl += parseInt(app['number'])
                        } else if (app['status'] === 'In Progress') {
                            appGRELBugNoDepl += parseInt(app['number'])
                        }
                    }
                    break;
                case 'Minor Release':
                    if (app['t_year'] !== '') {
                        greltotal += parseInt(app['number'])
                        if (app['status'] === 'Deployed') {
                            appGRELMinorDepl += parseInt(app['number'])
                        } else if (app['status'] === 'In Progress') {
                            appGRELMInorNoDepl += parseInt(app['number'])
                        }
                    }
                    break;
                case 'Pentest Findings':
                    if (app['status'] === 'Open') {
                        appRiskPentest += parseInt(app['number'])
                        if (app['risklevel'] === 'Low') {
                            appRiskPentestLow += parseInt(app['number']);
                        } else if (app['risklevel'] === 'Medium') {
                            appRiskPentestMedium += parseInt(app['number']);
                        } else if (app['risklevel'] === 'High') {
                            appRiskPentestHigh += parseInt(app['number']);
                        }
                    }
                    break;
                case 'Vulnerability Scan Finding':
                    if (app['status'] === 'Open') {
                        appRiskVulnerScan += parseInt(app['number'])
                        if (app['risklevel'] === 'Critical') {
                            appRiskVulnerScanCritical += parseInt(app['number']);
                        } else if (app['risklevel'] === 'High') {
                            appRiskVulnerScanHigh += parseInt(app['number']);
                        }
                    }
                    break;
                case 'Techreview Finding':
                    if (app['status'] === 'Open') {
                        appRiskTechReview += parseInt(app['number'])
                        if (app['risklevel'] === 'Low') {
                            appRiskTechReviewLow += parseInt(app['number']);
                        } else if (app['risklevel'] === 'Medium') {
                            appRiskTechReviewMedium += parseInt(app['number']);
                        } else if (app['risklevel'] === 'High') {
                            appRiskTechReviewHigh += parseInt(app['number']);
                        }
                    }
                    break;
                case 'CloudGuard Score':
                    if (app['country'] = 'ddm') {
                        appCGScore += parseInt(app['number'])
                    }
                    break;
                default:
                    break;
            }
        })
        setGrelTotal(greltotal)
        setGrelNormalDepl(appGRELNormalDepl)
        setGrelNormalNoDepl(appGRELNormalNoDepl)
        setGrelBugDepl(appGRELBugDepl)
        setGrelBugNoDepl(appGRELBugNoDepl)
        setGrelMinorDepl(appGRELMinorDepl)
        setGrelMinorNoDepl(appGRELMInorNoDepl)
        setRiskPentest(appRiskPentest)
        setRiskPentestLow(appRiskPentestLow)
        setRiskPentestMedium(appRiskPentestMedium)
        setRiskPentestHigh(appRiskPentestHigh)
        setRiskVulnerScan(appRiskVulnerScan)
        setRiskVulnerScanCritical(appRiskVulnerScanCritical)
        setRiskVulnerScanHigh(appRiskVulnerScanHigh)
        setRiskTechReview(appRiskTechReview)
        setRiskTechReviewLow(appRiskTechReviewLow)
        setRiskTechReviewMedium(appRiskTechReviewMedium)
        setRiskTechReviewHigh(appRiskTechReviewHigh)
        setCGHigh(appCGHigh)
        setCGMedium(appCGMedium)
        setCGLow(appCGLow)
        setCGScore(parseFloat((appCGScore).toFixed(2)))
        setAppNumber(appNumber)
        setAppNumberA(appNumberA)
        setAppNumberB(appNumberB)
        setAppNumberC(appNumberC)
    };

    useEffect(() => { // Initial load
        loadData();
    }, [loadData]) // [] required so that useEffect is called once

    function onSelectYear(year: string) {
        if (selectedYear === year) {
            year = '';
        }
        setSelectedYear(year);
        loadData();
    }

    // --- Create Plots ---- //

    function getColorForStatus(key: string, type: string, value: any): string {
        var color = "green";
        if (key === "Pentest") {
            color = "#eb8000";
        }
        else if (key === "High" && type === 'Pentest') {
            color = "#eb8000";
        }
        else if (key === "Medium" && type === 'Pentest') {
            color = "#ffb45a";
        }
        else if (key === "Low" && type === 'Pentest') {
            color = "#ffc681";
        }
        else if (key === "Vulnerability Scan") {
            color = "#eb8000";
        }
        else if (key === "Critical" && type === 'Vulnerability Scan') {
            color = "#eb8000";
        }
        else if (key === "High" && type === 'Vulnerability Scan') {
            color = "#ffb45a";
        }
        else if (key === "Medium" && type === 'Vulnerability Scan') {
            color = "#ffc681";
        }
        else if (key === "Low" && type === 'Vulnerability Scan') {
            color = "#ffc681";
        }
        else if (key === "Techreview") {
            color = "#2684ff";
        }
        else if (key === "High" && type === 'Techreview') {
            color = "#2684ff";
        }
        else if (key === "Medium" && type === 'Techreview') {
            color = "#59a1ff";
        }
        else if (key === "Low" && type === 'Techreview') {
            color = "#73afff";
        }
        else {
            color = "B3ECFF"
        }
        return color
    }

    function buildDatasetsYear(data: Map<string, Map<string, number>>, type: string) {
        let result: any[] = []
        data.forEach((value, key) => {

            result.push({
                label: key,
                data: [
                    value.has("2018") ? value.get("2018") : 0,
                    value.has("2019") ? value.get("2019") : 0,
                    value.has("2020") ? value.get("2020") : 0,
                    value.has("2021") ? value.get("2021") : 0,
                    value.has("2022") ? value.get("2022") : 0,
                    value.has("2023") ? value.get("2023") : 0,
                    value.has("2024") ? value.get("2024") : 0,
                ],
                backgroundColor: getColorForStatus(key, type, ''),
            })
        });
        return result;
    }

    function buildDatasetsQuarter(data: Map<string, Map<string, number>>, type: string) {
        let result: any[] = []
        data.forEach((value, key) => {

            result.push({
                label: key,
                data: [
                    value.has("1") ? value.get("1") : 0,
                    value.has("2") ? value.get("2") : 0,
                    value.has("3") ? value.get("3") : 0,
                    value.has("4") ? value.get("4") : 0,
                ],
                backgroundColor: getColorForStatus(key, type, ''),
            })
        });
        return result;
    }

    function sortRiskDetails(arr: Management[], priorityOrder: string[], orderType: string): Management[] {
        arr.sort((a, b) => {
            let priorityA = 0
            let priorityB = 0

            if (orderType === 'Year') {
                priorityA = priorityOrder.indexOf(a.t_year);
                priorityB = priorityOrder.indexOf(b.t_year);
            } else if (orderType === 'Quarter') {
                priorityA = priorityOrder.indexOf(a.t_quarter);
                priorityB = priorityOrder.indexOf(b.t_quarter);
            } else if (orderType === 'Risk') {
                priorityA = priorityOrder.indexOf(a.risklevel);
                priorityB = priorityOrder.indexOf(b.risklevel);
            } else {
                priorityA = priorityOrder.indexOf(a.t_year);
                priorityB = priorityOrder.indexOf(b.t_year);
            }

            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            } else {
                return priorityB - priorityA; // DESC, reverse if want ASC
            }
        });
        return arr;
    }

    function aggregateDataPlot(records: Management[], type: string, interval: string): Map<string, Map<string, number>> {
        let aggregated: Map<string, Map<string, number>> = new Map<string, Map<string, number>>();
        if (interval === 'year') {
            const priorityOrder = ["2018", "2019", "2020", "2021", "2022", "2023", "2024"];
            records = sortRiskDetails(records, priorityOrder, 'Year')
            records = records.filter(x => x.s_type === type)
            records.forEach(element => {
                if (aggregated.has(element.s_type)) {
                    let byStatus = aggregated.get(element.s_type);
                    if (byStatus!.has(element.t_year)) {
                        let currentMonthHours = byStatus!.get(element.t_year);
                        if (currentMonthHours !== undefined) {
                            byStatus!.set(element.t_year, currentMonthHours + (+parseInt(element.number)));
                        }
                    } else {
                        byStatus!.set(element.t_year, +parseInt(element.number));
                    }
                } else {
                    aggregated.set(element.s_type, new Map<string, number>([[element.t_year, +parseInt(element.number)]]))
                }
            });
        } else if (interval === 'quarter' && (type !== 'Pentest Findings' && type !== 'Techreview Finding' && type !== 'Vulnerability Scan Finding')) {
            const priorityOrder = ["1", "2", "3", "4"];
            records = sortRiskDetails(records, priorityOrder, 'Quarter')
            records = records.filter(x => x.s_type === type && (x.t_year === selectedYear || selectedYear === ''))
            records.forEach(element => {
                if (aggregated.has(element.s_type)) {
                    let byStatus = aggregated.get(element.s_type);
                    if (byStatus!.has(element.t_quarter)) {
                        let currentMonthHours = byStatus!.get(element.t_quarter);
                        if (currentMonthHours !== undefined) {
                            byStatus!.set(element.t_quarter, currentMonthHours + (+parseInt(element.number)));
                        }
                    } else {
                        byStatus!.set(element.t_quarter, +parseInt(element.number));
                    }
                } else {
                    aggregated.set(element.s_type, new Map<string, number>([[element.t_quarter, +parseInt(element.number)]]))
                }
            });
        } else if (interval === 'quarter' && (type === 'Pentest Findings' || type === 'Techreview Finding' || type === 'Vulnerability Scan Finding')) {
            const priorityOrder = ["1", "2", "3", "4"];
            records = sortRiskDetails(records, priorityOrder, 'Quarter')
            const riskOrder = ["Low", "Medium", "High", "Critical"];
            records = sortRiskDetails(records, riskOrder, 'Risk')
            records = records.filter(x => x.s_type === type && (x.t_year === selectedYear || selectedYear === ''))
            records.forEach(element => {
                if (aggregated.has(element.risklevel)) {
                    let byStatus = aggregated.get(element.risklevel);
                    if (byStatus!.has(element.t_quarter)) {
                        let currentMonthHours = byStatus!.get(element.t_quarter);
                        if (currentMonthHours !== undefined) {
                            byStatus!.set(element.t_quarter, currentMonthHours + (+parseInt(element.number)));
                        }
                    } else {
                        byStatus!.set(element.t_quarter, +parseInt(element.number));
                    }
                } else {
                    aggregated.set(element.risklevel, new Map<string, number>([[element.t_quarter, +parseInt(element.number)]]))
                }
            });
        }
        return aggregated;
    }

    function setOptionsPlot(titletext: string, showlegend: boolean, selectstacked: boolean) {
        const options = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    position: 'top' as const,
                    display: showlegend,
                },
                title: {
                    display: true,
                    text: titletext,
                },
            },
            scales: {
                x: {
                    stacked: selectstacked,
                },
                y: {
                    stacked: selectstacked,
                },
            },
        };
        return options
    }

    // Plot Pentest Results
    const labelsYear = ['2018', '2019', '2020', '2021', '2022', '2023', '2024'];
    const labelsQuarter = ['Q1', 'Q2', 'Q3', 'Q4'];

    let aggPentestYear = aggregateDataPlot(records, 'Pentest', 'year');
    let aggPentestQuarter = aggregateDataPlot(records, 'Pentest', 'quarter');
    let aggPentestFindingsQuarter = aggregateDataPlot(records, 'Pentest Findings', 'quarter');

    const dataPentestYear = {
        labels: labelsYear,
        datasets: buildDatasetsYear(aggPentestYear, 'Pentest'),
    }
    const dataPentestQuarter = {
        labels: labelsQuarter,
        datasets: buildDatasetsQuarter(aggPentestQuarter, 'Pentest'),
    }
    const dataPentestFindingsQuarter = {
        labels: labelsQuarter,
        datasets: buildDatasetsQuarter(aggPentestFindingsQuarter, 'Pentest'),
    }

    const optionsPentestYear = setOptionsPlot('Performed penetration tests - per year', true, true)
    const optionsPentestQuarter = setOptionsPlot('Performed penetration tests - per quarter', true, true)
    const optionsPentestFindingsQuarter = setOptionsPlot('New identifies findings - per quarter', true, true)

    // Plot Vulnerability Scan Results
    let aggVulnerScanYear = aggregateDataPlot(records, 'Vulnerability Scan', 'year');
    let aggVulnerScanQuarter = aggregateDataPlot(records, 'Vulnerability Scan', 'quarter');
    let aggVulnerScanFindingsQuarter = aggregateDataPlot(records, 'Vulnerability Scan Finding', 'quarter');

    const dataVulnerScanYear = {
        labels: labelsYear,
        datasets: buildDatasetsYear(aggVulnerScanYear, 'Vulnerability Scan'),
    }
    const dataVulnerScanQuarter = {
        labels: labelsQuarter,
        datasets: buildDatasetsQuarter(aggVulnerScanQuarter, 'Vulnerability Scan'),
    }
    const dataVulnerScanFindingsQuarter = {
        labels: labelsQuarter,
        datasets: buildDatasetsQuarter(aggVulnerScanFindingsQuarter, 'Vulnerability Scan'),
    }

    const optionsVulnerScanYear = setOptionsPlot('Performed vulnerablity scans - per year', true, true)
    const optionsVulnerScanQuarter = setOptionsPlot('Performed vulnerablity scans - per quarter', true, true)
    const optionsVulnerScanFindingsQuarter = setOptionsPlot('New identifies findings - per quarter', true, true)

    // Plot TechReview Results
    let aggTRYear = aggregateDataPlot(records, 'Techreview', 'year');
    let aggTRQuarter = aggregateDataPlot(records, 'Techreview', 'quarter');
    let aggTRFindingsQuarter = aggregateDataPlot(records, 'Techreview Finding', 'quarter');

    const dataTRYear = {
        labels: labelsYear,
        datasets: buildDatasetsYear(aggTRYear, 'Techreview'),
    }
    const dataTRQuarter = {
        labels: labelsQuarter,
        datasets: buildDatasetsQuarter(aggTRQuarter, 'Techreview'),
    }
    const dataTRFindingsQuarter = {
        labels: labelsQuarter,
        datasets: buildDatasetsQuarter(aggTRFindingsQuarter, 'Techreview'),
    }

    const optionsTRYear = setOptionsPlot('Performed TechReviews - per year', true, true)
    const optionsTRQuarter = setOptionsPlot('Performed TechReviews - per quarter', true, true)
    const optionsTRFindingsQuarter = setOptionsPlot('New identifies findings - per quarter', true, true)

    return (
        <>
            <h2>DD&M Management Overview {selectedYear && <>({selectedYear})</>}</h2>
            <div className="filter-list">
                {
                    yearsRecord.map((x, index) => (
                        <div key={index}
                            onClick={() => onSelectYear(x)}
                            className={x === selectedYear ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                            {x}
                        </div>
                    ))
                }
            </div>
            <br />
            <div style={{ display: "flex" }}>
                <div>
                    <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                        <SumBox size='l' color='#006384' icon={icon_cloud} number={appNumber} text='Number of Applications'></SumBox>
                        <span className="tooltip-content">{tipApps}</span>
                    </a>
                    <SumBox size='m' color='#006384' icon={icon_cloud} number={appNumberA} text='PNA - A'></SumBox>
                    <SumBox size='m' color='#0080ab' icon={icon_cloud} number={appNumberB} text='PNA - B'></SumBox>
                    <SumBox size='m' color='#00ade6' icon={icon_cloud} number={appNumberC} text='PNA - C'></SumBox>
                </div>
                <div>
                    <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                        <SumBox size='l' color='#253858' icon={icon_grel} number={greltotal} text='Number of Release Tickets'></SumBox>
                        <span className="tooltip-content">{tipGREL}</span>
                    </a>
                    <div style={{ display: "flex" }}>
                        <div>
                            <SumBox size='s' color='#253858' icon={icon_grel} number={grelnormalnodepl} text='Normal (In Progress)'></SumBox>
                            <SumBox size='s' color='#253858' number={grelbugnodepl} text='Bugfix (In Progress)'></SumBox>
                            <SumBox size='s' color='#253858' number={grelminornodepl} text='Minor (In Progress)'></SumBox>
                        </div>
                        <div>
                            <SumBox size='s' color='#5e81bb' icon={icon_grel} number={grelnormaldepl} text='Normal (Deployed)'></SumBox>
                            <SumBox size='s' color='#5e81bb' number={grelbugdepl} text='Bugfix (Deployed)'></SumBox>
                            <SumBox size='s' color='#5e81bb' number={grelminordepl} text='Minor (Deployed)'></SumBox>
                        </div>
                    </div>
                </div>
                <div>
                    <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                        <SumBox size='l' color='#5f1939' icon={icon_cloudguard} number={cgscore} text='Avg. CloudGuard Score'></SumBox>
                        <span className="tooltip-content">{tipCG}</span>
                    </a>
                    <div style={{ display: "flex" }}>
                        <div>
                            <SumBox size='m' color='#5f1939' number={cghigh} text='High'></SumBox>
                            <SumBox size='m' color='#ce4684' number={cgmedium} text='Medium'></SumBox>
                            <SumBox size='m' color='#df83ad' number={cglow} text='Low'></SumBox>
                        </div>
                    </div>
                </div>
                <div>
                    <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                        <SumBox size='l2' icon={icon_techreview} color='#2684ff' number={risktechreview} text='Tech Review Findings'></SumBox>
                        <span className="tooltip-content">{tipTR}</span>
                    </a>
                    <SumBox size='m2' color='#2684ff' number={risktechreviewhigh} text='High'></SumBox>
                    <SumBox size='m2' color='#59a1ff' number={risktechreviewmedium} text='Medium'></SumBox>
                    <SumBox size='m2' color='#73afff' number={risktechreviewlow} text='Low'></SumBox>
                </div>
                <div>
                    <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                        <SumBox size='l2' icon={icon_pentest} color='#ff991f' number={riskpentest} text='Pentest Findings'></SumBox>
                        <span className="tooltip-content">{tipPT}</span>
                    </a>
                    <SumBox size='m2' color='#ff991f' number={riskpentesthigh} text='High'></SumBox>
                    <SumBox size='m2' color='#ffb45a' number={riskpentestmedium} text='Medium'></SumBox>
                    <SumBox size='m2' color='#ffc681' number={riskpentestlow} text='Low'></SumBox>
                </div>
                <div>
                    <a role="tooltip" aria-haspopup="true" className="tooltip tooltip-xm">
                        <SumBox size='l2' icon={icon_vulnerscan} color='#ff991f' number={riskvulnerscan} text='Vulnerabilty Findings'></SumBox>
                        <span className="tooltip-content">{tipVS}</span>
                    </a>
                    <SumBox size='m3' color='#ff991f' number={riskvulnerscancritical} text='Critical'></SumBox>
                    <SumBox size='m3' color='#ffb45a' number={riskvulnerscanhigh} text='High'></SumBox>
                </div>
            </div>
            <h2>Pentest & Vulnerability Scan Insights</h2>
            <div>Note: In Q2 2020, all historical pentest findings prior to Q2 2020 were imported into Jira. The PoC phase for vulnerability scans started in Q4 23. </div>
            <div style={{ position: "relative", marginTop: "5px", height: "250px", width: "430px", display: "flex" }} >
                <Bar
                    options={optionsPentestYear}
                    data={dataPentestYear}
                    height={"200px"}
                />
                <Bar
                    options={optionsPentestQuarter}
                    data={dataPentestQuarter}
                    height={"200px"}
                />
                <Bar
                    options={optionsPentestFindingsQuarter}
                    data={dataPentestFindingsQuarter}
                    height={"200px"}
                />
            </div>
            <div style={{ position: "relative", marginTop: "5px", height: "250px", width: "430px", display: "flex" }} >
                <Bar
                    options={optionsVulnerScanYear}
                    data={dataVulnerScanYear}
                    height={"200px"}
                />
                <Bar
                    options={optionsVulnerScanQuarter}
                    data={dataVulnerScanQuarter}
                    height={"200px"}
                />
                <Bar
                    options={optionsVulnerScanFindingsQuarter}
                    data={dataVulnerScanFindingsQuarter}
                    height={"200px"}
                />
            </div>
            <h2>Tech Review Insights</h2>
            <div>Note: In Q2 2020, all historical findings prior to Q2 2020 were imported into Jira. </div>
            <div style={{ position: "relative", marginTop: "5px", height: "250px", width: "430px", display: "flex" }} >
                <Bar
                    options={optionsTRYear}
                    data={dataTRYear}
                    height={"200px"}
                />
                <Bar
                    options={optionsTRQuarter}
                    data={dataTRQuarter}
                    height={"200px"}
                />
                <Bar
                    options={optionsTRFindingsQuarter}
                    data={dataTRFindingsQuarter}
                    height={"200px"}
                />
            </div>
            <h2>CloudGuard Score - International View</h2>
            <div>Note: Current Cloudguard score of productive tenants grouped by country code. Green: score above 90%, yellow: score above 70%, red: score below 70%.</div>
            <table className="table-finding">
                <thead>
                    <tr>
                        {
                            headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        cloudguardDetails.map((cg, index) => (
                            <tr key={index}>
                                <td style={{ width: "20em" }}>{cg.country}</td>
                                <td style={{ width: "20em" }}>{cg.no_tenants}</td>
                                <td className='countCell item-select' style={{ width: "10em" }}>
                                    {
                                        parseInt(cg.number) >= 90 &&
                                        <span className="box-list-item success">{cg.number}%</span>
                                    }
                                    {
                                        parseInt(cg.number) < 90 &&
                                        parseInt(cg.number) >= 70 &&
                                        <span className="box-list-item info">{cg.number}%</span>
                                    }
                                    {
                                        parseInt(cg.number) < 70 &&
                                        <span className="box-list-item danger">{cg.number}%</span>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

export default ManagementSummary;
