import React from 'react';
import { useEffect, useState } from "react";
import GrcTable from "../../components/table/GrcTable";
import Legend from "../../components/table/Legend";
import { Application } from "../../models/Application";
import app_records from '../../data/19_1_grc_dashboard_app_view.json';
import export_time from '../../data/creation-dates.json';
import { CSVLink, CSVDownload } from 'react-csv';

// enum SearchFields {
//     SearchAll = "SearchAll"
// }

function ApplicationReport() {
    const records = app_records as Application[];

    const [applications, setApplications] = useState<Application[]>([])
    const [data, setData] = useState(""); // or useState<SearchParams>() -> where SearchParams is a class
    const [categories, setCategories] = useState<string[]>([])
    const [selectedCategory, setSelectedCategory] = useState("")
    const [countries, setCountries] = useState<string[]>([])
    const [selectedCountry, setSelectedCountry] = useState("")
    const [selectedCompliance, setSelectedCompliance] = useState("")
    const [selectedBIA, setSelectedBIA] = useState("")
    const [selectedPNA, setSelectedPNA] = useState("")
    const [totalApps, settotalApps] = useState<Application[]>([])


    function updateData(e: React.ChangeEvent<HTMLInputElement>) {
        setData(
            e.target.value
            // TODO how to put here object using Enum as properties? -> simple, useState<ObjectType>()
            //     {
            //     ...data,
            //     [e.target.name]: e.target.value
            // }
        )
        e.preventDefault();
        loadData(data, selectedCategory, selectedCountry, selectedCompliance, selectedBIA, selectedPNA, 1);
        if (e.target.value === "") {
            setData('')
            setSelectedCategory('');
            setSelectedCountry('');
            setSelectedPNA('');
            setSelectedBIA('');
            setSelectedCountry('');
            loadData('', '', '', '', '', '', 1);
        }

    }

    const UPDATETIME: string = export_time['19_1_grc_dashboard_app_view_json'];

    const headers: string[] = [
        "App ID and Name",
        "Criticality",
        "Release Tickets",
        "Risk Overview",
        "FS AG ICS Overview",
        "GuardDuty Overview",
        "CloudGuard Overview",
        "Release Readiness",
    ]

    const datasources: string[] = [
        "LeanIX",
        "LeanIX",
        "GREL Tickets In Progress / Total (Jira API)",
        "Pentest, Techreview Findings and Risks (Jira API)",
        "FS AG Internal Control System - ICS (Automated Check)",
        "Threat Analysis (GuardDuty API)",
        "CloudGuard Score and Finding (CloudGuard API)",
        "Automated Check",
    ]

    // function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    //     event.preventDefault();
    //     console.log(data);
    // }

    function loadData(searchText: string,
        category: string,
        country: string,
        compliantcheck: string,
        bia: string,
        pna: string,
        page: number) {
        setApplications(filterApplication(searchText, category, country, compliantcheck, bia, pna, records, page));
        settotalApps(filterApplication("", "", "", "", "", "", records, 1));
    }

    function filterApplication(searchText: string,
        category: string,
        country: string,
        compliantcheck: string,
        bia: string,
        pna: string,
        applications: Application[],
        pageNumber: number): Application[] {
        let filteredApps: Application[] = []
        // Search by all properties replaced just for Application Name, Category, Country
        // if (searchText) {
        //     applications.forEach(app => {
        //         if (Object.values(app).some(x => x && x.toLowerCase().indexOf(searchText.toLowerCase()) > -1)) {
        //             filteredApps.push(app);
        //         }
        //     })
        // } else {
        //     filteredApps = applications;
        // }
        applications.forEach(app => {
            if ((searchText === '' || (app.app_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1))
                && (category === '' || app.filter_category === category)
                && (country === '' || app.filter_country === country)
                && (bia === '' || app.app_bia === bia)
                && (pna === '' || app.app_pna === pna)
                && (compliantcheck === '' ||
                    (compliantcheck === 'true' &&
                        !(((app.current_cloudguardscore_workload_cons < 95 &&
                            app.current_cloudguardscore_workload_cons !== 0) ||
                            app.cloudguard_findings_high_cons !== 0 ||
                            app.cloudguard_findings_critical_cons !== 0) &&
                            app.risk_findings_techreview_cg === 0)) ||
                    (compliantcheck === 'false' &&
                        (((app.current_cloudguardscore_workload_cons < 95 &&
                            app.current_cloudguardscore_workload_cons !== 0) ||
                            app.cloudguard_findings_high_cons !== 0 ||
                            app.cloudguard_findings_critical_cons !== 0) &&
                            app.risk_findings_techreview_cg === 0))
                )
            ) {
                filteredApps.push(app);
            }
        })

        // TODO this should be calculated in the Backend API
        // setLastPage(Math.ceil(filteredApps.length / pageSize)); // calculate last page number for filtered
        // let totalItems = filteredApps.length;
        setCategories(applications.filter((thing, i, arr) => arr.findIndex(t => t.filter_category && (t.filter_category === thing.filter_category)) === i).map(x => x.filter_category));
        setCountries(applications.filter((thing, i, arr) => arr.findIndex(t => t.filter_country && (t.filter_country === thing.filter_country)) === i).map(x => x.filter_country));
        // filteredApps = filteredApps.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

        // setCurrentPage(pageNumber);
        // setItemsLabel(`Showing  ${totalItems > 0 ? (pageNumber - 1) * pageSize + 1 : 0}-${totalItems > (pageNumber * pageSize) ? (pageNumber * pageSize) : totalItems}/${totalItems} records.`);
        // TODO this should be calculated in the Backend API

        return filteredApps;
    }

    // function moveToPage(pageNumber: number) {
    //     loadData(data, selectedCategory, selectedCountry, pageNumber);
    //     setCurrentPage(pageNumber)
    // }

    useEffect(() => { // Initial load
        loadData('', '', '', '', '', '', 1);
    }, []) // [] required so that useEffect is called once

    function onSelectCategory(category: string) {
        if (selectedCategory === category) {
            category = "";
        }
        setSelectedCategory(category);
        loadData(data, category, selectedCountry, selectedCompliance, selectedBIA, selectedPNA, 1);
    }

    function onSelectCompliant(compliant: string) {
        if (selectedCompliance === compliant) {
            compliant = "";
        }
        setSelectedCompliance(compliant)
        loadData(data, selectedCategory, selectedCountry, compliant, selectedBIA, selectedPNA, 1);
    }

    function onSelectCountry(country: string) {
        if (selectedCountry === country) {
            country = "";
        }
        setSelectedCountry(country);
        loadData(data, selectedCategory, country, selectedCompliance, selectedBIA, selectedPNA, 1);
    }

    function onSelectBIA(bia: string) {
        if (selectedBIA === bia) {
            bia = "";
        }
        setSelectedBIA(bia);
        loadData(data, selectedCategory, selectedCountry, selectedCompliance, bia, selectedPNA, 1);
    }

    function onSelectPNA(pna: string) {
        if (selectedPNA === pna) {
            pna = "";
        }
        setSelectedPNA(pna);
        loadData(data, selectedCategory, selectedCountry, selectedCompliance, selectedBIA, pna, 1);
    }

    return (
        <>
            <div className="filter-list">
                {
                    categories.map((x, index) => (
                        <div key={index}
                            onClick={() => onSelectCategory(x)}
                            className={x === selectedCategory ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                            {x}
                        </div>
                    ))
                }
            </div>
            <br />
            <div className="filter-list">
                {
                    countries.map((x, index) => (
                        <div key={index}
                            onClick={() => onSelectCountry(x)}
                            className={x === selectedCountry ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                            {x}
                        </div>
                    ))
                }
                <div className="btn-group">
                    <input className='label' placeholder="Application Name" type="text" onChange={updateData} value={data}></input>
                </div>
            </div>
            <br />
            <div className="filter-list">
                <div
                    onClick={() => onSelectCompliant("true")}
                    className={selectedCompliance === "true" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    Release Ready
                </div>
                <div
                    onClick={() => onSelectCompliant("false")}
                    className={selectedCompliance === "false" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    Not Release Ready
                </div>
            </div>
            <br />
            <div className="filter-list">
                <div
                    onClick={() => onSelectBIA("Yes")}
                    className={selectedBIA === "Yes" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    BIA critical
                </div>
            </div>
            <div className="filter-list">
                <div
                    onClick={() => onSelectPNA("A")}
                    className={selectedPNA === "A" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    PNA: A
                </div>
            </div>
            <div className="filter-list">
                <div
                    onClick={() => onSelectPNA("B")}
                    className={selectedPNA === "B" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    PNA: B
                </div>
            </div>
            <div className="filter-list">
                <div
                    onClick={() => onSelectPNA("C")}
                    className={selectedPNA === "C" ? "filter-list-item filter-list-item-selected" : "filter-list-item"}>
                    PNA: C
                </div>
            </div>
            <div className="container-fluid">
                <div>

                    {
                        selectedCategory && selectedCountry && selectedCompliance &&
                        <div>
                            <h2>
                                <b>{selectedCategory} - Market {selectedCountry}
                                    {
                                        selectedCompliance === "true" &&
                                        <span> Release Ready</span>
                                    }
                                    {
                                        selectedCompliance === "false" &&
                                        <span> - Not Release Ready</span>
                                    }
                                    ({applications.length} / {totalApps.length})</b>
                            </h2>
                        </div>
                    }
                    {
                        !selectedCategory && selectedCountry &&
                        <div><h2><b>Market {selectedCountry}
                            {
                                selectedCompliance === "true" &&
                                <span> - Release Ready</span>
                            }
                            {
                                selectedCompliance === "false" &&
                                <span> - Not Release Ready</span>
                            }
                            ({applications.length} / {totalApps.length})</b></h2></div>
                    }
                    {
                        selectedCategory && !selectedCountry &&
                        <div><h2><b>{selectedCategory}
                            {
                                selectedCompliance === "true" &&
                                <span> - Release Ready</span>
                            }
                            {
                                selectedCompliance === "false" &&
                                <span> - Not Release Ready</span>
                            } ({applications.length} / {totalApps.length})</b></h2></div>
                    }
                    {
                        !selectedCategory && !selectedCountry && selectedCompliance &&
                        <div><h2><b>{
                            selectedCompliance === "true" &&
                            <div>Release Ready ({applications.length} / {totalApps.length})</div>
                        }
                            {
                                selectedCompliance === "false" &&
                                <div>Not Release Ready ({applications.length} / {totalApps.length})</div>
                            }</b></h2></div>
                    }
                    {
                        !selectedCategory && !selectedCountry && !selectedCompliance &&
                        <div><h2><b>All Applications ({applications.length})</b></h2></div>
                    }
                </div>
                <div>
                    <Legend updatetime={UPDATETIME}></Legend>
                </div>
            </div>
            <div>
            <CSVLink
                        data={applications}
                        filename={'product_owner_summary_' + Date.now()} 
                        target="_blank">
                        <button className='box-download' >
                            Download: Product Owner Summary
                        </button>
                    </CSVLink>
            </div>
            <div className="row flex-nowrap">
                    <GrcTable headers={headers} datasource={datasources} applications={applications}></GrcTable>
            </div>
        </>
    )
}

export default ApplicationReport;