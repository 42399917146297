import React from 'react';
import { Application } from "../../models/Application";
import NoteBox from "./NoteBox"
import TableCell from "./TableCell"
import { useNavigate } from "react-router-dom";
import detail_icon from "../../icons/details.png"

interface Props {
    headers: string[];
    datasource: string[];
    applications: Application[];
    //Defining function as an input to do some action in the Parent Component
    //   onSelectItem: (item: string) => void;
}

function GrcTable({ headers, datasource, applications }: Props) {

    const na_box = "n/a"
    const icda_box = "Incomplete Data"
    const ok_box = "OK"
    const gd_box = "Finding identified"
    const nocmpl_box = "Not Compliant"
    const risk_box = "Risks identified"

    const na_sf_text = "No data due to Salesforce Application."
    const na_grel_text = "No initial release identified."

    const cm_sf_text = "Automated monitoring in development. For more information see Playbooks for Salesforce and AWS."
    const risk_ok_text = "No risk identified."
    const gd_ok_text = "There are currently no High Findings."

    const resp_grc_text = "GRC will follow-up on the cause."
    const resp_com_dev_text = "Devs need to fix the findings."

    const navigate = useNavigate();

    function AppView(name: string, page: string) {

        const goToPosts = () => {
            navigate({
                pathname: page,
                search: '?appname=' + name,
            });
        }
        return goToPosts
    }

    function NextPentest(date: string, pna: string) {
        if (pna === "A") {
            date = date.slice(0, date.length - 2) + (parseInt(date.slice(date.length - 2, date.length)) + 1)
        } else {
            date = date.slice(0, date.length - 2) + (parseInt(date.slice(date.length - 2, date.length)) + 3)
        }
        return date
    }

    return (
        <>
            <table className="table-grc">
                <thead>
                    <tr>
                        {headers.map((item, index) => (
                            <th key={item}
                            >
                                {item}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {datasource.map((item, index) => (
                            <td key={item}
                            > <div>{item}</div>
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {applications.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <TableCell category="General">
                                    <b>{item.app_icto}</b> {item.app_name}
                                </TableCell>
                            </td>
                            <td>
                                {
                                    <TableCell category="General">
                                        {
                                            (item.app_pna) &&
                                            <div>PNA: {item.app_pna}</div>
                                        }
                                        {
                                            (!item.app_pna) &&
                                            <div>PNA: Missing</div>
                                        }
                                        {
                                            (item.app_bia) &&
                                            <div>BIA: {item.app_bia}</div>
                                        }
                                        {
                                            (!item.app_bia) &&
                                            <div>BIA: Missing</div>
                                        }
                                    </TableCell>
                                }
                            </td>
                            <td>
                                {
                                    (item.grel_normal_deployed +
                                        item.grel_normal_no_deployed +
                                        item.grel_minor_deployed +
                                        item.grel_minor_no_deployed +
                                        item.grel_bugfix_deployed +
                                        item.grel_bugfix_no_deployed === 0) &&
                                    <TableCell category="General" box={na_box}>{na_grel_text}</TableCell>

                                }
                                {!(item.grel_normal_deployed +
                                    item.grel_normal_no_deployed +
                                    item.grel_minor_deployed +
                                    item.grel_minor_no_deployed +
                                    item.grel_bugfix_deployed +
                                    item.grel_bugfix_no_deployed === 0) &&
                                    <TableCell category="General">
                                        {
                                            item.grel_normal_deployed + item.grel_normal_no_deployed !== 0 &&
                                            <div>Normal: {item.grel_normal_no_deployed}/{item.grel_normal_deployed + item.grel_normal_no_deployed}</div>
                                        }
                                        {
                                            item.grel_minor_deployed + item.grel_minor_no_deployed !== 0 &&
                                            <div>Minor: {item.grel_minor_no_deployed}/{item.grel_minor_deployed + item.grel_minor_no_deployed}</div>
                                        }
                                        {
                                            item.grel_bugfix_deployed + item.grel_bugfix_no_deployed !== 0 &&
                                            <div>Bugfix: {item.grel_bugfix_no_deployed}/{item.grel_bugfix_deployed + item.grel_bugfix_no_deployed}</div>
                                        }
                                        <a href=
                                            {'https://jira.platform.vwfs.io/issues/?jql=project%20%3D%20GREL%20AND%20%22ICTO%20ID%22%20in%20iqlFunction(%27Name%20%3D%20%22ICTO%22%20OR%20%22ICTO%20ID%22%20%3D%20%22ICTO-' + item.app_icto + '%22%27)'}>
                                            → GREL Tickets
                                        </a>
                                    </TableCell>
                                }
                            </td>
                            <td>
                                {
                                    (item.risk_findings_pentest === 0 &&
                                        item.risk_findings_techreview === 0 &&
                                        item.risk_findings_techreview_cg === 0 &&
                                        item.risk_findings_assessment === 0) &&
                                    <TableCell category="Compliant" box={ok_box}>{risk_ok_text}</TableCell>
                                }
                                {
                                    !(item.risk_findings_pentest === 0 &&
                                        item.risk_findings_techreview + item.risk_findings_techreview_cg === 0 &&
                                        item.risk_findings_assessment === 0) &&
                                    <TableCell category="Not Compliant"
                                        box={risk_box}
                                        resp_text={resp_com_dev_text}
                                        details={
                                            <div>
                                                <button className='box-details' onClick={AppView(item.app_name, 'risk-report')}>
                                                    <img style={{ height: '12px', paddingRight: '5px' }} src={detail_icon} alt="Batch Release" />
                                                    Details
                                                </button>
                                            </div>}>
                                        <div>The following risk was identified:</div>
                                        {
                                            item.risk_findings_pentest !== 0 &&
                                            <div><ul><li>Pentest: <span className='box-list-item danger'>{item.risk_findings_pentest}</span></li></ul></div>
                                        }
                                        {
                                            item.risk_findings_techreview + item.risk_findings_techreview_cg !== 0 &&
                                            <div><ul><li>Techreview: <span className='box-list-item danger'>{item.risk_findings_techreview + item.risk_findings_techreview_cg}</span></li></ul></div>
                                        }
                                        {
                                            item.risk_findings_assessment !== 0 &&
                                            <div><ul><li>Assessment: <span className='box-list-item danger'>{item.risk_findings_assessment}</span></li></ul></div>
                                        }
                                    </TableCell>
                                }
                            </td>
                            <td>
                                {
                                    (item.ics_findings_compliant === 0 &&
                                        item.ics_findings_noncompliant === 0) &&
                                    <TableCell category="Incomplete Data" box={icda_box} resp_text={resp_grc_text}>{cm_sf_text}</TableCell>
                                }
                                {
                                    (item.ics_findings_compliant !== 0 &&
                                        item.ics_findings_compliant / (item.ics_findings_compliant + item.ics_findings_noncompliant) * 100 >= 90) &&
                                    <div className='tableCell divCompliant'>
                                        <div><span className='box-list-item success'>{parseFloat((100 * item.ics_findings_compliant / (item.ics_findings_compliant + item.ics_findings_noncompliant)).toFixed(2))}%</span> of the controls are compliant</div>
                                        <div>{item.ics_findings_compliant}/{item.ics_findings_compliant + item.ics_findings_noncompliant} controls fulfilled.</div>
                                        <div>
                                            <button className='box-details' onClick={AppView(item.app_name, '/compliance-insight/control-appview')}>
                                                <img style={{ height: '12px', paddingRight: '2px' }} src={detail_icon} alt="Batch Release" />
                                                Details
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    (item.ics_findings_compliant !== 0 &&
                                        item.ics_findings_compliant / (item.ics_findings_compliant + item.ics_findings_noncompliant) * 100 < 90) &&
                                    <div className='tableCell divNotCompliant'>
                                        <div><span className='box-list-item danger'>{parseFloat((100 * item.ics_findings_compliant / (item.ics_findings_compliant + item.ics_findings_noncompliant)).toFixed(2))}%</span> of the controls are compliant</div>
                                        <div>{item.ics_findings_compliant}/{item.ics_findings_compliant + item.ics_findings_noncompliant} controls fulfilled.</div>
                                        <NoteBox resp='DEV' acc='PO' con='GRC' text='Devs need to fix the non-compliant controls.'></NoteBox>
                                        <div>
                                            <button className='box-details' onClick={AppView(item.app_name, '/compliance-insight/control-appview')}>
                                                <img style={{ height: '12px', paddingRight: '2px' }} src={detail_icon} alt="Batch Release" />
                                                Details
                                            </button>
                                        </div>
                                    </div>
                                }
                            </td>
                            <td>
                                {
                                    item.filter_category === "Salesforce" &&
                                    <TableCell category="General" box={na_box}>{na_sf_text}</TableCell>
                                }
                                {
                                    (item.filter_category !== "Salesforce" &&
                                        !item.app_tenant) &&
                                    <TableCell category="Incomplete Data" box={icda_box} resp_text={resp_grc_text}>No AWS Tenant identified.</TableCell>
                                }
                                {
                                    (item.app_tenant &&
                                        item.guardduty_findings_high === 0) &&
                                    <TableCell category="Compliant" box={ok_box}>{gd_ok_text}</TableCell>

                                }
                                {
                                    (item.app_tenant &&
                                        item.guardduty_findings_high !== 0) &&
                                    <TableCell category="Not Compliant" box={gd_box} resp_text={resp_com_dev_text}>
                                        <div> High Findings: {item.guardduty_findings_high}</div>
                                    </TableCell>
                                }
                            </td>
                            <td>
                                {
                                    item.filter_category === "Salesforce" &&
                                    <TableCell category="General" box={na_box}>{na_sf_text}</TableCell>

                                }
                                {
                                    (item.filter_category !== "Salesforce" &&
                                        item.current_cloudguardscore_workload === 0) &&
                                    <TableCell category="Incomplete Data" box={icda_box} resp_text={resp_grc_text}>No AWS Tenant identified.</TableCell>
                                }
                                {
                                    (item.current_cloudguardscore_workload >= 95 &&
                                        item.current_cloudguardscore_workload !== 0 &&
                                        item.cloudguard_findings_critical === 0 &&
                                        item.cloudguard_findings_high === 0) &&
                                    <TableCell
                                        category="Security Compliant"
                                        current_cloudguardscore_workload={item.current_cloudguardscore_workload}
                                        preview_cloudguardscore_workload={item.preview_cloudguardscore_workload}
                                        cloudguard_findings_medium={item.cloudguard_findings_medium}
                                        cloudguard_findings_low={item.cloudguard_findings_low}
                                    ></TableCell>
                                }
                                {
                                    ((item.current_cloudguardscore_workload < 95 &&
                                        item.current_cloudguardscore_workload !== 0) ||
                                        item.cloudguard_findings_critical > 0 ||
                                        item.cloudguard_findings_high > 0) &&
                                    <TableCell
                                        category="Security Not Compliant"
                                        current_cloudguardscore_workload={item.current_cloudguardscore_workload}
                                        preview_cloudguardscore_workload={item.preview_cloudguardscore_workload}
                                        cloudguard_findings_critical={item.cloudguard_findings_critical}
                                        cloudguard_findings_high={item.cloudguard_findings_high}
                                        cloudguard_findings_medium={item.cloudguard_findings_medium}
                                        cloudguard_findings_low={item.cloudguard_findings_low}
                                    ></TableCell>
                                }
                            </td>
                            <td>
                                {
                                    !(((item.current_cloudguardscore_workload_cons < 95 &&
                                        item.current_cloudguardscore_workload_cons !== 0) ||
                                        item.cloudguard_findings_high_cons !== 0 ||
                                        item.cloudguard_findings_critical_cons !== 0) &&
                                        item.risk_findings_techreview_cg === 0) &&
                                    <div className='tableCell divCompliant'>
                                        <TableCell category="Release Compliant">
                                            {
                                                (item.grel_normal_deployed !== 0 ||
                                                    item.grel_bugfix_deployed !== 0 ||
                                                    item.grel_minor_deployed !== 0) &&
                                                (item.grel_normal_no_deployed === 0 &&
                                                    item.grel_bugfix_no_deployed === 0 &&
                                                    item.grel_minor_no_deployed === 0) &&
                                                <div>
                                                    LoD compliant for next release.
                                                </div>
                                            }
                                            {
                                                ((item.grel_normal_no_deployed !== 0 ||
                                                    item.grel_bugfix_no_deployed !== 0 ||
                                                    item.grel_minor_no_deployed !== 0)) &&
                                                <div>
                                                    LoD compliant for {item.grel_normal_no_deployed + item.grel_bugfix_no_deployed + item.grel_minor_no_deployed} releases.
                                                </div>
                                            }

                                            {
                                                ((item.grel_normal_deployed === 0 &&
                                                    item.grel_bugfix_deployed === 0 &&
                                                    item.grel_minor_deployed === 0) &&
                                                    (item.grel_normal_no_deployed === 0 &&
                                                        item.grel_bugfix_no_deployed === 0 &&
                                                        item.grel_minor_no_deployed === 0)) &&
                                                <div>LoD compliant for initial release.</div>
                                            }
                                            {
                                                (item.risk_findings_pentest !== 0 ||
                                                    item.risk_findings_techreview_cg + item.risk_findings_techreview !== 0) &&
                                                <div><b>Identified Findings</b></div>
                                            }
                                            {
                                                item.risk_findings_pentest !== 0 &&
                                                <div><ul><li>Pentest: <span className='box-list-item danger'>{item.risk_findings_pentest}</span></li></ul></div>
                                            }
                                            {
                                                item.risk_findings_techreview_cg + item.risk_findings_techreview !== 0 &&
                                                <div><ul><li>Techreview: <span className='box-list-item danger'>{item.risk_findings_techreview + item.risk_findings_techreview_cg}</span></li></ul></div>
                                            }
                                            {
                                                item.risk_findings_techreview_cg !== 0 &&
                                                <NoteBox resp='DEV' acc='PO' con='GRC' text={'Close ' + item.risk_findings_techreview_cg + ' Tech Review finding regarding CloudGuard'}></NoteBox>

                                            }
                                            {
                                                item.risk_findings_techreview !== 0 ||
                                                item.risk_findings_pentest !== 0 &&
                                                <NoteBox resp='DEV' acc='PO' con='GRC' text='Devs need to fix the findings before due date.'></NoteBox>
                                            }
                                            {
                                                item.pentest_last &&
                                                item.filter_category !== "Salesforce" &&
                                                <NoteBox resp='DEV' acc='PO' con='GRC' text={<div>Last Pentest: {item.pentest_last} - Next Pentest must be planned for a release before: {NextPentest(item.pentest_last, item.app_pna)}. <br></br><a href="https://jira.platform.vwfs.io/servicedesk/customer/portal/37/create/505/"> Request Pentest </a></div>}></NoteBox>
                                            }
                                            {
                                                !item.pentest_last &&
                                                item.filter_category !== "Salesforce" &&
                                                (item.grel_normal_no_deployed !== 0 ||
                                                    item.grel_bugfix_no_deployed !== 0 ||
                                                    item.grel_minor_no_deployed !== 0 ||
                                                    item.grel_normal_deployed !== 0 ||
                                                    item.grel_bugfix_deployed !== 0 ||
                                                    item.grel_minor_deployed !== 0) &&
                                                <NoteBox resp='DEV' acc='PO' con='GRC' text={<div>No Pentest identified - Pentest must be planned for the next release. <br></br><a href="https://jira.platform.vwfs.io/servicedesk/customer/portal/37/create/505/"> Request Pentest </a></div>}></NoteBox>
                                            }
                                        </TableCell>
                                    </div>

                                }
                                {
                                    (((item.current_cloudguardscore_workload_cons < 95 &&
                                        item.current_cloudguardscore_workload_cons !== 0) ||
                                        item.cloudguard_findings_high_cons !== 0 ||
                                        item.cloudguard_findings_critical_cons !== 0) &&
                                        item.risk_findings_techreview_cg === 0) &&
                                    <TableCell category="Not Compliant" box={nocmpl_box}>
                                        {
                                            item.grel_normal_no_deployed + item.grel_bugfix_no_deployed + item.grel_minor_no_deployed === 0 &&
                                            <div>Currently not LoD compliant next release due to:</div>
                                        }
                                        {
                                            item.grel_normal_no_deployed + item.grel_bugfix_no_deployed + item.grel_minor_no_deployed === 1 &&
                                            <div>Currently not LoD compliant for {item.grel_normal_no_deployed + item.grel_bugfix_no_deployed + item.grel_minor_no_deployed} release due to:</div>
                                        }
                                        {
                                            item.grel_normal_no_deployed + item.grel_bugfix_no_deployed + item.grel_minor_no_deployed > 1 &&
                                            <div>Currently not LoD compliant for {item.grel_normal_no_deployed + item.grel_bugfix_no_deployed + item.grel_minor_no_deployed} releases due to:</div>
                                        }
                                        {
                                            (item.risk_findings_pentest !== 0 ||
                                                item.risk_findings_techreview !== 0) &&
                                            <div><b>Identified Findings</b></div>
                                        }
                                        {
                                            item.risk_findings_pentest !== 0 &&
                                            <div><ul><li>Pentest: <span className='box-list-item danger'>{item.risk_findings_pentest}</span></li></ul></div>
                                        }
                                        {
                                            item.risk_findings_techreview !== 0 &&
                                            <div><ul><li>Techreview: <span className='box-list-item danger'>{item.risk_findings_techreview}</span></li></ul></div>
                                        }
                                        {
                                            (item.risk_findings_pentest !== 0 ||
                                                item.risk_findings_techreview !== 0) &&
                                            <div>
                                                <button className='box-details' onClick={AppView(item.app_name, 'risk-report')}>
                                                    <img style={{ height: '12px', paddingRight: '5px' }} src={detail_icon} alt="Batch Release" />
                                                    Details
                                                </button>
                                            </div>
                                        }
                                        {
                                            ((item.current_cloudguardscore_workload_cons < 95 &&
                                                item.current_cloudguardscore_workload_cons !== 0) ||
                                                (item.cloudguard_findings_critical_cons > 0) ||
                                                (item.cloudguard_findings_high_cons > 0)) &&
                                            <div><b>CloudGuard (CONS)</b></div>
                                        }
                                        {
                                            (item.current_cloudguardscore_workload_cons < 95 &&
                                                item.current_cloudguardscore_workload_cons !== 0) &&
                                            <div><ul><li>Score: <span className='box-list-item danger'>{parseFloat(item.current_cloudguardscore_workload_cons.toFixed(2))}%</span></li></ul></div>
                                        }
                                        {
                                            (item.current_cloudguardscore_workload_cons !== 0 &&
                                                item.cloudguard_findings_critical_cons > 0) &&
                                            <div><ul><li>Critical Findings: <span className='box-list-item danger'>{item.cloudguard_findings_critical_cons}</span></li></ul></div>
                                        }
                                        {
                                            (item.current_cloudguardscore_workload_cons !== 0 &&
                                                item.cloudguard_findings_high_cons > 0) &&
                                            <div><ul><li>High Findings: <span className='box-list-item danger'>{item.cloudguard_findings_high_cons}</span></li></ul></div>
                                        }
                                        {
                                            ((item.current_cloudguardscore_workload_cons < 95 &&
                                                item.current_cloudguardscore_workload_cons !== 0) ||
                                                (item.cloudguard_findings_critical_cons > 0) ||
                                                (item.cloudguard_findings_high_cons > 0)) &&
                                            <NoteBox resp='DEV' acc='PO' con='GRC' text='Please contact the Tech Reviewer or GRC to create a Tech Review finding for CloudGuard.'></NoteBox>
                                        }
                                        {
                                            item.pentest_last &&
                                            item.filter_category !== "Salesforce" &&
                                            <NoteBox resp='DEV' acc='PO' con='GRC' text={'Last Pentest: ' + item.pentest_last + ' - Next Pentest must be planned for a release before: ' + NextPentest(item.pentest_last, item.app_pna)}></NoteBox>
                                        }
                                        {
                                            !item.pentest_last &&
                                            item.filter_category !== "Salesforce" &&
                                            (item.grel_normal_no_deployed !== 0 ||
                                                item.grel_bugfix_no_deployed !== 0 ||
                                                item.grel_minor_no_deployed !== 0 ||
                                                item.grel_normal_deployed !== 0 ||
                                                item.grel_bugfix_deployed !== 0 ||
                                                item.grel_minor_deployed !== 0) &&
                                            <NoteBox resp='DEV' acc='PO' con='GRC' text={'No Pentest identified - Pentest must be planned for the next release.'}></NoteBox>
                                        }
                                    </TableCell>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default GrcTable;