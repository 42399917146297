import React, { Component } from 'react';

class SumBox extends Component<any, any> {

    render() {

        return <>
            {
                this.props.size === "l" &&
                <div style={{
                    backgroundColor: this.props.color,
                    height: '110px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '250px'
                }}>
                    <div style={{
                        fontSize: '40px',
                        textAlign: 'right',
                        color: 'white',
                        fontWeight: '700',
                        padding: '5px'
                    }}>
                        {
                            this.props.icon &&
                            <img className='icon_left' src={this.props.icon} />
                        }
                        {this.props.number}
                    </div>
                    <div style={{
                        fontSize: '20px',
                        textAlign: 'left',
                        color: 'white',
                        paddingLeft: '5px'
                    }}>
                        {this.props.text}
                    </div>
                </div>
            }
            {
                this.props.size === "l2" &&
                <div style={{
                    backgroundColor: this.props.color,
                    height: '110px',
                    marginTop: '10px',
                    marginRight: '10px',
                    width: '210px'
                }}>
                    <div style={{
                        fontSize: '40px',
                        textAlign: 'right',
                        color: 'white',
                        fontWeight: '700',
                        padding: '5px'
                    }}>
                        {
                            this.props.icon &&
                            <img className='icon_left' src={this.props.icon} />
                        }
                        {this.props.number}
                    </div>
                    <div style={{
                        fontSize: '20px',
                        textAlign: 'left',
                        color: 'white',
                        paddingLeft: '5px'
                    }}>
                        {this.props.text}
                    </div>
                </div>
            }
            {
                this.props.size === "m" &&
                <div style={{
                    backgroundColor: this.props.color,
                    height: '60px',
                    margin: '2px',
                    width: '246px'
                }}>
                    <div style={{
                        fontSize: '22px',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: '700',
                    }}>
                        {this.props.number}
                    </div>
                    <div style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        color: 'white',
                        paddingLeft: '5px'
                    }}>
                        {this.props.text}
                    </div>
                </div>
            }
            {
                this.props.size === "m2" &&
                <div style={{
                    backgroundColor: this.props.color,
                    height: '60px',
                    margin: '2px',
                    width: '206px'
                }}>
                    <div style={{
                        fontSize: '22px',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: '700',
                    }}>
                        {this.props.number}
                    </div>
                    <div style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        color: 'white',
                        paddingLeft: '5px'
                    }}>
                        {this.props.text}
                    </div>
                </div>
            }
             {
                this.props.size === "m3" &&
                <div style={{
                    backgroundColor: this.props.color,
                    height: '91px',
                    margin: '2px',
                    width: '206px'
                }}>
                    <div style={{
                        fontSize: '22px',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: '700',
                    }}>
                        {this.props.number}
                    </div>
                    <div style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        color: 'white',
                        paddingLeft: '5px'
                    }}>
                        {this.props.text}
                    </div>
                </div>
            }
            {
                this.props.size === "s" &&
                <div style={{
                    backgroundColor: this.props.color,
                    height: '60px',
                    margin: '2px',
                    width: '120px'
                }}>
                    <div style={{
                        fontSize: '22px',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: '700',
                        padding: '2px'
                    }}>
                        {this.props.number}
                    </div>
                    <div style={{
                        fontSize: '12px',
                        textAlign: 'center',
                        color: 'white',
                        paddingLeft: '5px'
                    }}>
                        {this.props.text}
                    </div>
                </div>
            }
        </>
    }
}

export default SumBox